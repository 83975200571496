<alert></alert>


<!-- 
<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<div class="wrapper">
  <app-header class="container-fluid header" style="z-index: 999;" id="header-container"></app-header> -->

<div class="container-fluid container-blue-theme">
  <!-- <div class="row"> -->
  <!-- page content here -->
  <router-outlet></router-outlet>
  <!-- page content ends -->
  <!-- </div> -->

</div>


<!-- </div> -->
<!-- wrapper ends -->
