<div class="my-5">
  <div class="row">
    <div class="col-auto">
      <figure class="avatar avatar-60 border-0"><img src="../../../assets/images/bussiness-man.svg" alt=""></figure>
    </div>
    <div class="col pl-0 align-self-center">
      <h5 class="mb-1">{{profile.name}}</h5>
      <p class="text-mute small">{{profile.username}}</p>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="list-group main-menu">
      <a class="list-group-item list-group-item-action" (click)="clickHome()"><i
          class="material-icons icons-raised">home</i>หน้าหลัก</a>
      <a class="list-group-item list-group-item-action" (click)="clickPromotion()"><i
          class="material-icons icons-raised">notifications</i>โปรโมชั่น </a>
      <a class="list-group-item list-group-item-action" (click)="clickReport()"><i
          class="material-icons icons-raised">find_in_page</i>รายงาน</a>
      <a class="list-group-item list-group-item-action" (click)="clickContact()" href="{{linkLine}}" target="_blank"><i
          class="material-icons icons-raised">chat</i>ติดต่อ</a>
      <a class="list-group-item list-group-item-action" (click)="clickAccount()"><i
          class="material-icons icons-raised">account_box</i>บัญชีผู้ใช้</a>
      <a class="list-group-item list-group-item-action" (click)="clickLogout()"><i
          class="material-icons icons-raised bg-danger">power_settings_new</i>ออกจากระบบ</a>
    </div>
  </div>
</div>
