<div class="wrapper">
  <div class="header">
    <div class="row no-gutters">
      <!-- <div class="col-auto">
        <a class="btn btn-link" (click)="goToLogin()"><i class="material-icons">keyboard_arrow_left</i></a>
      </div> -->
      <div class="col text-center"><img src="assets/images/logo-header.png" alt="" class="header-logo"></div>
      <!-- <div class="col-auto">
        <a href="/layout/profile" class="btn  btn-link text-dark"><i class="material-icons">account_circle</i></a>
      </div> -->
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-10 mx-auto text-center mt-4">
        <br>
        <img src="assets/images/404.png" alt="" class="mw-100">
        <br>
        <h5 class="mt-5">Page not found</h5>
        <p class="text-secondary">Content you are looking for is not found. Please check url or link</p>
        <br>
        <button class="btn btn-rounded shadow-sm" (click)="goToLogin()">Go
          to
          login <i class="material-icons md-18">arrow_forward</i></button>
      </div>
    </div>
  </div>
</div>
