<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">ประวัติการฝากถอน</li>
      </ol>
    </nav>

    <div class="container">
      <div class="row">
        <div class="col-6 text-center pt-1 pb-1" [ngClass]="isShowDeposit ? 'click-active':''" (click)="clickDeposit()"
          style="border: 1px solid;
          border-radius: 5px 0px 0px 5px;
          border-color: #9c9c9c; 
          border-right-width:0px">
          ประวัติการฝาก
        </div>
        <div class="col-6 text-center pt-1 pb-1" [ngClass]="!isShowDeposit ? 'click-active':''"
          (click)="clickWithdraw()" style="border: 1px solid;
          border-radius: 0px 5px 5px 0px;
          border-color: #9c9c9c;
          border-left-width:0px">
          ประวัติการถอน
        </div>
      </div>
    </div>

    <hr>

    <div *ngIf="isShowDeposit">
      <div class="container">
        <div class="row">
          <div class="col">
            <div *ngIf="timeSet == 'today'">
              <div style="font-size: 20px;">รายการวันนี้</div>
              <div class="text-mute" style="font-size: 12px;">{{dateShowToday}}</div>
            </div>

            <div *ngIf="timeSet == 'week' || timeSet == 'month'|| timeSet == 'lastweek'|| timeSet == 'lastmonth'">
              <div style="font-size: 20px;">รายการ</div>
              <div class="text-mute" style="font-size: 12px;">{{dateRangeShow}}</div>

            </div>

          </div>
          <div class="col-5">
            <div class="form-group" style="margin-top: 10px;">
              <select name="inputBankName" class=" form-control text-center" style="border-radius: 7px;
            font-size: 15px;" [(ngModel)]="timeSet" (change)="changeTimeSetDeposit($event)" required autofocus>
                <option value="today">วันนี้</option>
                <option value="week">สัปดาห์นี้</option>
                <option value="lastweek">สัปดาห์ที่แล้ว</option>
                <option value="month">เดือนนี้</option>
                <option value="lastmonth">เดือนที่แล้ว</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2" *ngIf="!noData">

        <div class="container mt-2 box-tran p-3" *ngFor="let item of depositList; let i =index" id="box-{{i}}">
          <div class="row" (click)="showDetail(item._id)">
            <div class="col-auto">
              <div style="font-size: 17px; font-weight: bold;">ฝากเงิน</div>
              <div class="text-mute" style="font-size: 12px;">{{item.createDate | date:'dd/MM/yyyy HH:mm'}} น.</div>
            </div>
            <div class="col text-right">
              <div style="font-size: 17px; color: green;">{{item.value | number:'1.2-2'}} บาท</div>
              <div><i [ngClass]="isShow == true && id == item._id ? 'fas fa-caret-up':'fas fa-caret-down'"></i>
              </div>
            </div>
          </div>
          <div *ngIf="isShow && id == item._id" [@inOutAnimation]>
            <hr class="mt-1 mb-1">
            <div class="row">
              <div class="col-auto">
                <div style="font-size: 14px; color: #585757;">โบนัส</div>
              </div>
              <div class="col text-right">
                <div style="font-size: 14px; color: #585757;">{{item.bonus | number: '1.2-2'}} บาท</div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto">
                <div style="font-size: 14px; color: #585757;">ยอดที่ได้รับ</div>
              </div>
              <div class="col text-right">
                <div style="font-size: 14px; color: #585757;">{{item.topUp | number:'1.2-2'}} บาท</div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto">
                <div style="font-size: 14px; color: #585757;">หมายเหตุ</div>
              </div>
              <div class="col text-right">
                <div style="font-size: 14px; color: #585757;">{{item.remark}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isShowDeposit">
      <div class="container">
        <div class="row">
          <div class="col">
            <div *ngIf="timeSet == 'today'">
              <div style="font-size: 20px;">รายการวันนี้</div>
              <div class="text-mute" style="font-size: 12px;">{{dateShowToday}}</div>
            </div>

            <div *ngIf="timeSet == 'week' || timeSet == 'month'|| timeSet == 'lastweek'|| timeSet == 'lastmonth'">
              <div style="font-size: 20px;">รายการ</div>
              <div class="text-mute" style="font-size: 12px;">{{dateRangeShow}}</div>

            </div>

          </div>
          <div class="col-5">
            <div class="form-group" style="margin-top: 10px;">
              <select name="inputBankName" class=" form-control text-center" style="border-radius: 7px;
            font-size: 15px;" [(ngModel)]="timeSet" (change)="changeTimeSetWithdraw($event)" required autofocus>
                <option value="today">วันนี้</option>
                <option value="week">สัปดาห์นี้</option>
                <option value="lastweek">สัปดาห์ที่แล้ว</option>
                <option value="month">เดือนนี้</option>
                <option value="lastmonth">เดือนที่แล้ว</option>

              </select>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!noData">
        <div class="container mt-2 box-tran p-3" *ngFor="let item of withdrawList">
          <div class="row" (click)="showDetail(item._id)">
            <div class="col-auto">
              <div style="font-size: 17px; font-weight: bold;">ถอนเงิน</div>
              <div class="text-mute" style="font-size: 12px;">{{item.createDate | date:'dd/MM/yyyy HH:mm'}} น.</div>
            </div>
            <div class="col text-right">
              <div style="font-size: 17px; color: red;">{{item.value | number:'1.2-2'}} บาท</div>
              <div *ngIf="item.status != 'MANUAL' && item.status != 'SUCCESS' && item.status != 'REJECT'"> <span
                  style="color: #22a7f0; font-size: 14px;">{{item.status | statusWithdraw}} </span> <i
                  [ngClass]="isShow == true && id == item._id ? 'fas fa-caret-up':'fas fa-caret-down'"></i>
              </div>

              <div *ngIf="item.status == 'MANUAL' || item.status == 'SUCCESS'"> <span
                  style="color: green; font-size: 14px;">{{item.status | statusWithdraw}} </span> <i
                  [ngClass]="isShow == true && id == item._id ? 'fas fa-caret-up':'fas fa-caret-down'"></i>
              </div>

              <div *ngIf="item.status == 'REJECT'"> <span
                  style="color: red; font-size: 14px;">{{item.status | statusWithdraw}} </span> <i
                  [ngClass]="isShow == true && id == item._id ? 'fas fa-caret-up':'fas fa-caret-down'"></i>
              </div>
            </div>
          </div>
          <div *ngIf="isShow && id == item._id" [@inOutAnimation2]>
            <hr class="mt-1 mb-1">

            <div class="row">
              <div class="col-auto">
                <div style="font-size: 14px; color: #585757;">หมายเหตุ</div>
              </div>
              <div class="col text-right">
                <div style="font-size: 14px; color: #585757;">{{item.remark || '-'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="noData" class="text-center">
        ไม่มีประวัติการฝากเงิน
      </div>
    </div>
  </div>
  <app-loading-page [isLoading]="isLoading"></app-loading-page>
