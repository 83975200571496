<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">ฝากเงิน True Money</li>
      </ol>
    </nav>

    <div class="container">
      <div class="row mt-4 mb-4 pt-3 pb-3" style="border: 2px solid;
    border-radius: 7px;
    border-color: #FC9C42;" *ngFor="let item of truemoney">
        <div class="col-auto" style="margin: 5px -20px 10px 10px;">
          <img style="width: 70px; height: auto;" src="../../assets/images/true-money.png" alt="">

        </div>
        <div class="col">
          <div class="">ชื่อบัญชี {{item.name}} </div>
          <div class="text-mute">เบอร์โทร</div>
          <h3>{{item.phoneNumber}}</h3>
          <button class="btn" style="border-color: #4a98ff; color: #fff; background-color: #4a98ff;"
            (click)="copyPhone(item.phoneNumber)">คัดลอกเบอร์โทร</button>
        </div>
      </div>
    </div>

  </div>
</div>
<!-- page content ends -->
<!-- </div>
    </div> -->
<!-- </div> -->
<!-- wrapper ends -->

<app-loading-page [isLoading]="isLoading"></app-loading-page>
