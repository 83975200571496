<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" id="header-container"></app-header>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">โปรโมชั่น</li>
      </ol>
    </nav>
    <div class="container">
      <div class="mt-3">
        <h3>โปรโมชั่น</h3>
        <div class="row">
          <div class="col-6" *ngFor="let item of promotionList; index as i">
            <div class="card shadow border-0 mb-4" (click)="openModal(template, item.img)">
              <div class="card-body p-3">
                <img id="myImg" style="width: 100%;" src="{{item.img}}">
                <!-- <img style="width: 100%;" [src]="item.img" (click)="open(item.img)" /> -->
                <!-- <img style="width: 100%;" [lightbox]="i" [src]="item.data.src"> -->
                <!-- <div class="mt-1 mb-0 font-weight-normal text-menu">โปรโมชั่น</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template style="width: 100%; margin-top: 50%;" #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">โปรโมชั่น</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <img src="{{imgPreview}}" style="width: 100%; height: auto;">
  </div>

</ng-template>

<app-loading-page [isLoading]="isLoading"></app-loading-page>
