<div class="login-page">
  <div class="wrapper vh-100">

    <div class="row no-gutters h-100 login-row">
      <div class="col align-self-center px-3 text-center">
        <br>
        <img src="{{logo}}" alt="logo" class="logo-small">
        <form class="form-signin mt-3 ">
          <div class="form-group">
            <input type="email" name="inputEmail" [(ngModel)]="userName"
              class="form-control form-control-lg text-center" placeholder="ชื่อผู้ใช้งาน" required autofocus>
          </div>

          <div class="form-group">
            <show-hide-password size="lg" [btnOutline]="false">
              <input type="password" name="inputPassword" [(ngModel)]="password"
                class="form-control form-control-lg text-center" placeholder="รหัสผ่าน" required>
            </show-hide-password>
          </div>
          <a class="mt-4 d-block" (click)="onForgetPassword()">ลืมรหัสผ่าน ?</a>

          <!-- login buttons -->
          <!-- <div class="row mx-0 bottom-button-container"> -->
          <div style="margin-top: 30%;">
            <button (click)="onLoggedin()"
              class="btn btn-lg btn-rounded shadow btn-block btn_login">เข้าสู่ระบบ</button>
          </div>

          <div style="margin-top: 10%;">
            <hr>
          </div>
          <div style="margin-top: 10%;">
            <button (click)="onRegister()" class="btn btn-lg btn-rounded shadow btn-block">สมัครสมาชิก</button>
          </div>
          <!-- </div> -->
          <!-- login buttons -->
        </form>
      </div>
    </div>



  </div>
</div>
