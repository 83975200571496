import { AlertService } from './../../_alert/alert.service';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showMenu: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;
  token;
  prefix;
  profile = {
    name: '',
    username: ''
  };
  logo;
  line = '';


  @Output() collapsedEvent = new EventEmitter<boolean>();

  constructor(public router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private ref: ChangeDetectorRef
  ) {

    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');
  }

  ngOnInit() {
    this.showMenu = '';
    this.getPrefix();
    this.getProfile();
  }

  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      if (res.code == 0) {
        this.logo = res.result.logo;
        this.line = res.result.line
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  get linkLine() {
    return this.line;
  }

  getProfile() {
    this.walletService.getProfile(this.token).then((res: any) => {
      if (res.code == 0) {
        this.profile = res.result;
        this.ref.detectChanges();
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
    })
  }


  eventCalled() {
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  clickHome() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });

    this.toggleSidebar()
  }

  clickPromotion() {
    this.router.navigate(['/promotion'], { queryParams: { prefix: this.prefix } });

    this.toggleSidebar()

  }

  clickReport() {
    this.router.navigate(['/report'], { queryParams: { prefix: this.prefix } });

    this.toggleSidebar()

  }

  clickContact() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });

    this.toggleSidebar()

  }

  clickAccount() {
    this.router.navigate(['/account'], { queryParams: { prefix: this.prefix } });

    this.toggleSidebar()

  }

  clickLogout() {
    localStorage.setItem('token', '')
    window.location.reload()

  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    if (window.innerWidth <= 992) {
      if (dom.classList.contains('sidemenu-open')) {
        dom.classList.remove('sidemenu-open');
        setTimeout(() => {
          dom.classList.remove('menuactive');
        }, 800);
      }
    }
  }


}
