import { DepositCouponPageComponent } from './../deposit-coupon-page/deposit-coupon-page.component';
import { AlertService } from './../_alert/alert.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { WalletService } from 'src/service/wallet.service';
import 'swiper/dist/css/swiper.min.css';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})


export class DashboardPageComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;
  token;
  profile = {
    name: '',
    username: '',
    credit: null,
    point: null
  };
  prefix;
  logo;
  isDisableChshback = true;
  linkNewReport;
  linkReward;
  cashback;
  isLoading = false;
  isPoint: any;
  showPoint = ''
  checkAuthPrefix: any;
  isMobile = false
  dateReload: any

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header: any = document.querySelector('.header');

    const pos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 30) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }

  public configurations: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  public configurationstwo: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 2,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
    },
  };

  public configurationsoffer: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 0,
  };

  public configurationsnews: SwiperConfigInterface = {
    slidesPerView: 2,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private clipboard: Clipboard,
    private matDialog: MatDialog

  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit() {
    const dom: any = document.querySelector('body');
    dom.classList.remove('sidemenu-open');
    dom.classList.remove('menuactive');
    if (window.orientation > -1) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    this.clickNewReport();
    this.clickReward()
    this.getPrefix()
    this.getProfile()
    this.checkCashback()
  }

  getPrefix() {
    this.isLoading = true
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      this.isLoading = true
      if (res.code == 0) {
        this.checkAuthPrefix = res.result
        this.isPoint = res.result.isNewAffiliate ? true : false
        this.logo = res.result.logo

      } else {
        this.alertService.error(res.message, this.options)
      }
      // this.isLoading = false

    })
  }


  getProfile() {
    this.isLoading = true
    this.walletService.getProfile(this.token).then((res: any) => {
      if (res.code == 0) {
        console.log('yes');
        this.dateReload = new Date()
        this.profile = res.result;
        this.showPoint = this.isPoint ? res.result.point : res.result.wallet
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false

    })
  }

  copyUser() {
    this.clipboard.copy(this.profile.username);
    this.alertService.success('คัดลอกสำเร็จ', this.options);
  }

  depositAuto() {
    this.router.navigate(['/deposit-auto'], { queryParams: { prefix: this.prefix } });

  }

  depositDecimal() {
    this.router.navigate(['/deposit-decimal'], { queryParams: { prefix: this.prefix } });

  }

  clickWithdraw() {
    this.router.navigate(['/withdraw'], { queryParams: { prefix: this.prefix } });

  }

  depositTrueMoney() {
    this.router.navigate(['/deposit-truemoney'], { queryParams: { prefix: this.prefix } });
  }

  depositAskmepay() {
    this.router.navigate(['/deposit-askmepay'], { queryParams: { prefix: this.prefix } });
  }

  openCoupon() {
    this.router.navigate(['/deposit-coupon'], { queryParams: { prefix: this.prefix } });
  }

  clickReport() {
    this.router.navigate(['/report'], { queryParams: { prefix: this.prefix } });
  }

  clickPromotion() {
    this.router.navigate(['/promotion'], { queryParams: { prefix: this.prefix } });
  }

  clickAffiliate() {
    this.router.navigate(['/link-get-point'], { queryParams: { prefix: this.prefix } });
  }

  clickWithdrawPoint() {
    this.router.navigate(['/withdraw-point'], { queryParams: { prefix: this.prefix } });
  }

  clickCreditCard() {
    this.router.navigate(['/deposit-credit-card'], { queryParams: { prefix: this.prefix } });
  }
  clickEntertain() {
    this.router.navigate(['/movie'], { queryParams: { prefix: this.prefix } });
  }


  clickNewReport() {
    this.isLoading = true
    this.walletService.newReportAffiliate(this.token).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        this.linkNewReport = res.result
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }

  clickReward() {
    this.isLoading = true
    this.walletService.getReward(this.token).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        this.linkReward = res.result
      } else if (res.code == 1005) {
        localStorage.removeItem('token');
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }

  checkCashback() {
    this.isLoading = true
    this.walletService.checkCashback(this.token).then((res: any) => {
      console.log(res);

      if (res.code == 0) {
        this.cashback = res.result
        this.isDisableChshback = this.cashback > 0 ? false : true
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.cashback = 0
        // this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }

  clickGetCashBack() {
    this.isLoading = true
    this.walletService.getCashback(this.token).then((res: any) => {
      console.log(res);

      if (res.code == 0) {
        this.alertService.success(res.message, this.options)
        this.checkCashback();
        this.getProfile();
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }
  updateProfile() {
    this.getProfile();
    this.cashback();
  }

  get getLinkReward() {
    return this.linkReward;
  }

  get getLinkNewReport() {
    return this.linkNewReport;
  }

}
