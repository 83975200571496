import { Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { AlertService } from '../_alert';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('dataContainer') dataContainer: ElementRef;
  passwordPattern = '/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/'
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  newPassword: string = ''
  confirmPassword: string = ''

  changePassword = {
    newPassword: '',
    confirmPassword: ''
  }
  prefix;
  logo = ''
  token = ''
  isLoading = false;



  passwordForm = new FormGroup({
    newPassword: new FormControl(['', [Validators.required]]),
    confirmPassword: new FormControl(['', [Validators.required]]),
  })

  isDisableChangePassword = true;
  collapedSideBar: boolean;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService
  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }

    this.passwordForm.valueChanges.subscribe(value => {
      console.log(value);
      this.newPassword = value.newPassword

      if (this.newPassword.match('^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{8,}$')) {
        if (value.newPassword.length >= 8 && value.confirmPassword.length >= 8) {
          this.isDisableChangePassword = false
        } else {
          this.isDisableChangePassword = true
        }
      } else {
        this.isDisableChangePassword = true
      }

    })
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit(): void {
    this.getPrefix();
  }

  getPrefix() {
    this.isLoading = true
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false

    })
  }

  back() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });

  }

  clickChangePassword() {
    console.log(this.changePassword.newPassword, this.changePassword.confirmPassword);

    if (this.changePassword.newPassword.match(this.changePassword.confirmPassword)) {
      const body = {
        "password": this.changePassword.newPassword
      }
      this.isLoading = true
      this.walletService.changePasswordNew(this.token, body).then((res: any) => {
        console.log(res);
        if (res.code == 0) {
          this.alertService.success(res.message, this.options)
          this.isLoading = false
          setTimeout(() => {
            localStorage.removeItem('token')
            this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
          }, 3000);
        } else if (res.code == 1005) {
          localStorage.removeItem('token')
          this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
        } else {
          this.alertService.error(res.message, this.options)
        }
      })
    } else {
      this.alertService.error('รหัสผ่านใหม่ไม่ตรงกัน', this.options)
    }

  }

}
