<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>

  <div class="container">
    <div class="card bg-template shadow mt-4 ">
      <div class="card-body">
        <div class="row">
          <div class="col-auto">
            <figure class="avatar avatar-60"><img style="width: 100%;" src="../../assets/images/bussiness-man.svg"
                alt=""></figure>
          </div>
          <div class="col pl-0 align-self-center">
            <h5 class="mb-1">{{profile.name || ''}}</h5>
            <p class="text-mute small">{{profile.username || ''}}</p>
          </div>

        </div>
        <button class="btn" style="border-color: #fff; color: #fff; font-size: 10px; float: right;"
          (click)="copyUser()">คัดลอกยูสเซอร์</button>

      </div>
    </div>
  </div>
  <div class="container">
    <div class="card mb-4 shadow">
      <div class="card-body border-bottom">
        <div class="row">
          <div class="col-6">
            <p class="text-mute">Credit</p>
            <h4 class="mb-0 font-weight-normal"> {{profile.credit | number: '1.2-2'}} </h4>
          </div>
          <div class="col-6">
            <p class="text-mute">Point</p>
            <h4 class="mb-0 font-weight-normal"> {{showPoint | number: '1.2-2'}} </h4>
          </div>
          <div class="col text-center" style="font-size: 14px;
          margin-right: 8%;" (click)="updateProfile()"><i class="fas fa-sync-alt"></i> อัพเดทโปรไฟล์ล่าสุด
            {{dateReload | date:'dd/MM/yyyy HH:mm:ss'}}
          </div>
          <!-- <div class="col-auto">
              <button class="btn btn-default btn-rounded-54 shadow"><i class="material-icons">add</i></button>
            </div> -->
        </div>
      </div>
      <div class="card-footer bg-none">
        <div class="row">
          <div class="col">
            <p class="text-mute">Cash Back</p>
            <h6 class="mb-0 font-weight-normal"> {{cashback | number:'1.2-2'}} </h6>
          </div>
          <div class="col" style="margin-top: auto;">
            <!-- <p class="text-mute">Cash Back</p> -->
            <!-- <h4 class="mb-0 font-weight-normal"> 20,000.00 </h4> -->
            <button style="border-radius: 6px;" [disabled]="isDisableChshback" (click)="clickGetCashBack()">กดรับ
              แคชแบ็ก</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col">
        <h4 class="section-title mb-3">

          <span>ช่องทางการฝากเงิน</span>
        </h4>

        <div class="row" style="margin-bottom :20px">
          <div class=" col-6 col-md-3 col-lg-3 " (click)="depositAuto()">

            <div class="block_deposit" style="margin-bottom: 20px; border-color: #FFBE70; background-color: #FFDEB5;">
              <i class=" vm md-36 text-template"> <img style="width: 35px;
                height: auto;" src="../../assets/images/cash-back.svg"></i>

              ฝากเงินออโต้
            </div>

          </div>
          <div class=" col-6 col-md-3 col-lg-3 " (click)="depositDecimal()">
            <div class="block_deposit" style=" margin-bottom: 20px; border-color: #6ADFFC; background-color: #CAF3FE;">
              <i class=" vm md-36 text-template"> <img style="width: 35px;
                height: auto;" src="../../assets/images/money-transfer.svg"></i>
              ฝากเงินทศนิยม
            </div>
          </div>
          <!-- </div> -->

          <!-- <div class="row" style="margin-bottom :20px"> -->
          <div class=" col-6 col-md-3 col-lg-3 " (click)="depositTrueMoney()">
            <div class="block_deposit" style="margin-bottom: 20px; border-color: #88FC6A; background-color: #D4FFC9;">
              <i class=" vm md-36 text-template"> <img style="width: 35px;
                height: auto;" src="../../assets/images/true-money.png"></i>
              True Money
            </div>
          </div>
          <div class=" col-6 col-md-3 col-lg-3 " (click)="depositAskmepay()">
            <div class="block_deposit" style=" margin-bottom: 20px; border-color: #FC8DF9; background-color: #FFDCFE;">
              <i class=" vm md-36 text-template"> <img style="width: 35px;
                height: auto;" src="../../assets/images/askmepay-2.png"></i>
              Askmepay
            </div>

          </div>
          <!-- </div> -->

          <!-- <div class="row" style="margin-bottom :20px"> -->
          <div class=" col-6 col-md-3 col-lg-3 " (click)="clickCreditCard()">

            <div class="block_deposit" style="margin-bottom: 20px; border-color: #b9f7ff; background-color: #dbfbfa;">
              <i class=" vm md-36 text-template"> <img style="width: 35px;
                height: auto;" src="../../assets/images/credit-cards.svg"></i>
              Credit Card
            </div>

          </div>


          <div class=" col-6 col-md-3 col-lg-3 " (click)="openCoupon()">

            <div class="block_deposit" style="margin-bottom: 20px; border-color: #FCF98D; background-color: #FEFCCE;">
              <i class=" vm md-36 text-template"> <img style="width: 35px;
                height: auto;" src="../../assets/images/coupon.svg"></i>
              ใช้รหัสคูปอง
            </div>

          </div>
          <!-- </div> -->

          <div class="col-6 col-md-3 col-lg-3 " (click)="clickReward()">

            <div class="block_deposit"
              style=" margin-bottom: 20px; border-color: #E3D0FF; background-color: #ece0fd; color: #000;">
              <a target="_blank" style="color: #000;" href="{{getLinkReward}}">
                <i class=" vm md-36 text-template"> <img style="width: 35px;
                height: auto;" src="../../assets/images/reward.svg"></i>
                Reward
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


  <div class="container">
    <div class="row">
      <div class="col">
        <h4 class="section-title mb-0">
          <span>เมนู</span>
        </h4>
        <!-- <p class="text-secondary">Take a look at our services</p> -->
      </div>
    </div>
    <div class="row text-center mt-4">
      <div class="col-4 col-md-2">
        <div class="card shadow border-0 mb-4">
          <div class="card-body p-0" (click)="clickWithdraw()">
            <div class="avatar avatar-60 no-shadow border-0">
              <!-- <div class="overlay bg-template"></div> -->
              <i class=" vm md-36 text-template"> <img src="../../assets/images/get-money.svg"></i>
            </div>
            <div class="mt-1 mb-0 font-weight-normal text-menu">ถอนเงิน</div>
          </div>
        </div>
      </div>
      <div class="col-4 col-md-2">
        <div class="card shadow border-0 mb-4">
          <div class="card-body p-0" (click)="clickWithdrawPoint()">
            <div class="avatar avatar-60 no-shadow border-0">
              <!-- <div class="overlay bg-template"></div> -->
              <i class=" vm md-36 text-template"> <img src="../../assets/images/withdraw.svg"></i>
            </div>
            <div class="mt-1 mb-0 font-weight-normal text-menu">ถอนรายได้</div>
          </div>
        </div>
      </div>
      <div class="col-4 col-md-2">
        <div class="card shadow border-0 mb-4">
          <a class="card-body p-0" (click)="clickNewReport()" target="_blank" href="{{getLinkNewReport}}">
            <div class="avatar avatar-60 no-shadow border-0">
              <!-- <div class="overlay bg-template"></div> -->
              <i class=" vm md-36 text-template"> <img src="../../assets/images/affiliate.svg"></i>
            </div>
            <div class="mt-1 mb-0 font-weight-normal text-menu" style="color: #000;">รายงานรายได้</div>
          </a>
        </div>
      </div>
      <div class="col-4 col-md-2">
        <div class="card shadow border-0 mb-4">
          <div class="card-body p-0" (click)="clickReport()">
            <div class="avatar avatar-60 no-shadow border-0">
              <!-- <div class="overlay bg-template"></div> -->
              <i class=" vm md-36 text-template"> <img src="../../assets/images/immigration.svg"></i>
            </div>
            <div class="mt-1 mb-0 font-weight-normal text-menu">รายงาน</div>
          </div>
        </div>
      </div>
      <div class="col-4 col-md-2">
        <div class="card shadow border-0 mb-4">
          <div class="card-body p-0" (click)="clickPromotion()">
            <div class="avatar avatar-60 no-shadow border-0">
              <!-- <div class="overlay bg-template"></div> -->
              <i class=" vm md-36 text-template"> <img src="../../assets/images/promotion.svg"></i>
            </div>
            <div class="mt-1 mb-0 font-weight-normal text-menu">โปรโมชั่น</div>
          </div>
        </div>
      </div>
      <div class="col-4 col-md-2">
        <div class="card shadow border-0 mb-4">
          <div class="card-body p-0" (click)="clickAffiliate()">
            <div class="avatar avatar-60 no-shadow border-0">
              <!-- <div class="overlay bg-template"></div> -->
              <i class=" vm md-36 text-template"> <img src="../../assets/images/share.svg"></i>
            </div>
            <div class="mt-1 mb-0 font-weight-normal text-menu">ลิ้งค์รับรายได้</div>
          </div>
        </div>
      </div>
      <div class="col-4 col-md-2">
        <div class="card shadow border-0 mb-4">
          <div class="card-body p-0" (click)="clickEntertain()">
            <div class="avatar avatar-60 no-shadow border-0">
              <!-- <div class="overlay bg-template"></div> -->
              <i class=" vm md-36 text-template"> <img src="../../assets/images/cinema.svg"></i>
            </div>
            <div class="mt-1 mb-0 font-weight-normal text-menu">เอนเตอร์เทน</div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
<!-- page content ends -->
<!-- </div>
    </div> -->
<!-- </div> -->
<!-- wrapper ends -->
<app-loading-page [isLoading]="isLoading"></app-loading-page>
