import { FilterModule } from './pipe/filter/filter.module';
import { LinkGetPointPageComponent } from './link-get-point-page/link-get-point-page.component';
import { AlertModule } from './_alert';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { SignupPageComponent } from './signup-page/signup-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgbAlertModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { ForgetPasswordPageComponent } from './forget-password-page/forget-password-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SuccessSendPasswordComponent } from './success-send-password/success-send-password.component';
import { DepositDecimalPageComponent } from './deposit-decimal-page/deposit-decimal-page.component';
import { DepositAutoPageComponent } from './deposit-auto-page/deposit-auto-page.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DepositDecimalInfoPageComponent } from './deposit-decimal-info-page/deposit-decimal-info-page.component';
import { CountdownModule } from 'ngx-countdown';
import { WithdrawPageComponent } from './withdraw-page/withdraw-page.component';
import { DepositTruemoneyPageComponent } from './deposit-truemoney-page/deposit-truemoney-page.component';
import { DepositAskmepayPageComponent } from './deposit-askmepay-page/deposit-askmepay-page.component';
import { DepositCouponPageComponent } from './deposit-coupon-page/deposit-coupon-page.component';
import { ReportPageComponent } from './report-page/report-page.component';
import { PromotionPageComponent } from './promotion-page/promotion-page.component';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { GalleryModule } from '@ngx-gallery/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { WithdrawPointPageComponent } from './withdraw-point-page/withdraw-point-page.component';
import { DepositCreditCardPageComponent } from './deposit-credit-card-page/deposit-credit-card-page.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AccountPageComponent } from './account-page/account-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { MoviesComponent } from './movies/movies.component';
import { MovieDetailComponent } from './movie-detail/movie-detail.component';
import { PlayMovieComponent } from './play-movie/play-movie.component';



const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {

};
@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    SignupPageComponent,
    DashboardPageComponent,
    ErrorPageComponent,
    HeaderComponent,
    SidebarComponent,
    ForgetPasswordPageComponent,
    SuccessSendPasswordComponent,
    DepositDecimalPageComponent,
    DepositAutoPageComponent,
    DepositDecimalInfoPageComponent,
    WithdrawPageComponent,
    DepositTruemoneyPageComponent,
    DepositAskmepayPageComponent,
    DepositCouponPageComponent,
    ReportPageComponent,
    PromotionPageComponent,
    LinkGetPointPageComponent,
    WithdrawPointPageComponent,
    DepositCreditCardPageComponent,
    ChangePasswordComponent,
    AccountPageComponent,
    ContactPageComponent,
    LoadingPageComponent,
    MoviesComponent,
    MovieDetailComponent,
    PlayMovieComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgbAlertModule,
    AlertModule,
    SwiperModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSlideToggleModule,
    ClipboardModule,
    CountdownModule,
    LightboxModule,
    GalleryModule,
    ModalModule.forRoot(),
    FilterModule,
    NgxPaginationModule,
    ShowHidePasswordModule
  ],
  exports: [
    AlertModule
  ],

  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, {
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
