<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">เปลี่ยนพาสเวิร์ด</li>
      </ol>
    </nav>

    <div class="mt-3">
      <h3 class="text-center">เปลี่ยนรหัสผ่าน</h3>
      <form [formGroup]="passwordForm" class="form-signin mt-3 ">
        <div class="form-group">
          <label>รหัสผ่านใหม่</label>
          <input type="password" name="inputPassword" formControlName="newPassword"
            [(ngModel)]="changePassword.newPassword" class="form-control form-control-lg text-left"
            placeholder="รหัสผ่านใหม่" [ngClass]="changePassword.newPassword.length < 8 ? 'req': ''" required autofocus>
          <p style="font-size: 12px; text-align: left;" class="text-danger">*รหัสผ่านต้องมีA-Zหรือa-zและตัวเลข
            อย่างน้อย8ตัว</p>
        </div>

        <div class="form-group">
          <label>ยืนยันรหัสผ่านใหม่</label>
          <input type="password" name="inputPassword" formControlName="confirmPassword"
            [(ngModel)]="changePassword.confirmPassword" class="form-control form-control-lg text-left"
            placeholder="ยืนยันรหัสผ่านใหม่" [ngClass]="changePassword.confirmPassword.length < 8 ? 'req': ''" required
            autofocus>

        </div>

      </form>
    </div>

    <div style="text-align: center;
    margin-top: 12%;">
      <button class="btn btn-lg btn-rounded shadow btn_login" (click)="clickChangePassword()"
        [disabled]="isDisableChangePassword">เปลี่ยนรหัสผ่าน</button>
    </div>


  </div>
</div>

<app-loading-page [isLoading]="isLoading"></app-loading-page>
