<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" id="header-container"></app-header>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">เอนเตอร์เทน</li>
      </ol>
    </nav>
    <div class="row"
      style="margin: 15px; display: flex; flex-wrap: wrap; text-align: center; justify-content: space-evenly;">
      <div *ngFor="let item of movieList"
        style="max-width: 140px; margin: 0.5em; position: relative; height: 220px; margin-bottom: 2em; cursor:pointer"
        (click)="onSelect(item.id)">
        <div
          style="position: absolute; top: 5px; left: 5px; width: 34px; height: 16px; background: #FF0000; z-index: 1; border-radius: 3px; padding: 1px; font-size: 10px;">
          <img src="assets/svg/star.svg"> {{item.score}}</div>

        <div class="itemsContainer">
          <div class="image"> <a href="#"> <img class="img-preview" src="{{item.image}}" /> </a></div>
          <div class="play"><img style="margin-top: 40%;"
              src="http://cdn1.iconfinder.com/data/icons/flavour/button_play_blue.png" /> </div>
        </div>
        <!-- <img class="img-preview" src={{item.image}}> -->
        <div>{{item.title}}</div>
        <div style="font-size: 10px; color: #B3B3B3;">{{item.sound}}</div>
        <div
          [ngClass]="{'fullhd': item.resolution == 'FullHD', 'hd': item.resolution == 'HD', 'zm': item.resolution == 'ZM'}"
          style="position: absolute; right: 5px; bottom: 60px; width: 38px; height: 16px; border-radius: 3px; padding: 1px; font-size: 10px;">
          {{item.resolution}}
        </div>
      </div>
    </div>
  </div>
  <div class="list">
    <div *ngFor="let item of totalPage | paginate: {
            itemsPerPage: 20,
            currentPage: currentPage,
            totalItems:  totalPage}"></div>
    <pagination-controls class="paginator" previousLabel="" nextLabel=""
      (pageChange)="currentPage = $event; pageChanged($event)">
    </pagination-controls>
  </div>

</div>
<app-loading-page [isLoading]="isLoading"></app-loading-page>
