import { AlertService } from './../_alert/alert.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { WalletService } from 'src/service/wallet.service';
import 'swiper/dist/css/swiper.min.css';
import { Clipboard } from '@angular/cdk/clipboard';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-withdraw-point-page',
  templateUrl: './withdraw-point-page.component.html',
  styleUrls: ['./withdraw-point-page.component.scss']
})
export class WithdrawPointPageComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;
  token;
  prefix;
  profile;
  bankDeposit;
  logo;
  isDisableAmount = true;
  clickEvent;
  amount = '';
  isDisableWithdraw = true;
  withdrawCondition;
  flag = false;
  isLoading = false

  amountForm = new FormGroup({
    amount: new FormControl(['', [Validators.required]])
  });

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header: any = document.querySelector('.header');

    const pos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 30) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }

  public configurations: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };

  public configurationstwo: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 2,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
    },
  };

  public configurationsoffer: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    spaceBetween: 0,
  };

  public configurationsnews: SwiperConfigInterface = {
    slidesPerView: 2,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
    },
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 0
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 0
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private clipboard: Clipboard
  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      localStorage.setItem('prefix', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    this.amountForm.valueChanges.subscribe(value => {
      console.log(+(value.amount));
      console.log(this.flag);


      if (+(value.amount) >= 0.5 && this.flag == true) {
        this.isDisableWithdraw = false
      } else {
        this.isDisableWithdraw = true
      }
    })
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit() {
    const dom: any = document.querySelector('body');
    dom.classList.remove('sidemenu-open');
    dom.classList.remove('menuactive');
    this.getProfile()
    this.getPrefix()
    this.condition()

    console.log(this.profile.point);

  }

  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  getProfile() {
    this.isLoading = true
    this.walletService.getProfile(this.token).then((res: any) => {
      if (res.code == 0) {
        this.profile = res.result;
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }


  back() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
  }

  condition() {
    this.isLoading = true
    this.walletService.conditionPoint(this.token).then((res: any) => {
      if (res.code == 0) {
        console.log(res);

        this.withdrawCondition = res.result
        this.flag = res.result.flag;

        if (res.result.flag) {
          this.isDisableWithdraw = false
        } else {
          this.isDisableWithdraw = true
        }
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }

  withdraw() {
    this.isLoading = true
    const body = {
      amount: this.amount
    }
    this.walletService.withdrawPoint(this.token, body).then((res: any) => {
      if (res.code == 0) {
        this.alertService.success(res.message, this.options);
        setTimeout(() => {
          this.isLoading = false
          this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
        }, 3000);
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options);
      }
    })
  }

  copyBank() {
    this.clipboard.copy(this.profile.accountNumber);
    this.alertService.success('คัดลอกสำเร็จ', this.options);

  }

}
