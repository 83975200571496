import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { AlertService } from '../_alert';
import { Clipboard } from '@angular/cdk/clipboard';
import { animate, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-out',
              style({ height: 68, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 68, opacity: 1 }),
            animate('0.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'inOutAnimation2',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.5s ease-out',
              style({ height: 23, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 23, opacity: 1 }),
            animate('0.5s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]

})
export class ReportPageComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;
  token;
  prefix;
  profile;
  bankDeposit;
  logo;
  isDisableChshback = true;
  isShow = false
  isShow2 = false
  isShowDeposit = true

  timeSet = 'today';

  depositList = []
  withdrawList = []

  dateShowToday;
  dateRangeShow;
  noData = true;
  id: any;
  public truemoney = []
  isLoading = false;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header: any = document.querySelector('.header');

    const pos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 30) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private clipboard: Clipboard
  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit(): void {
    this.getPrefix()
    this.setTimeRange('today', 'deposit')
  }

  setTimeRange(value, type) {

    if (value == 'today') {
      let now = new Date()
      let date = ("0" + now.getDate()).slice(-2) + "/" + ("0" + (now.getMonth() + 1)).slice(-2) + "/" + now.getFullYear();
      let date2 = ("0" + now.getDate()).slice(-2) + "/" + ("0" + (now.getMonth() + 1)).slice(-2) + "/" + now.getFullYear();
      let start = this.GetFormattedDate(date);
      let end = this.GetFormattedDate(date2);
      let timeRange = {
        startDate: start,
        endDate: end,
        // startTime: '00:00',
        // endTime: '00:00'
      }
      this.dateShowToday = date
      if (type == 'deposit') {
        this.getReportDeposit(timeRange);

      } else if (type == 'withdraw') {
        console.log('get Withdraw');
        this.getReportWithdraw(timeRange);

      }
    } else if (value == 'week') {
      var curr = new Date();
      var day = curr.getDay();
      var date1 = new Date(curr.getTime() - 60 * 60 * 24 * day * 1000);
      var date2 = new Date(date1.getTime() + 60 * 60 * 24 * 6 * 1000);
      var startDate = ("0" + date1.getDate()).slice(-2) + "/" + ("0" + (date1.getMonth() + 1)).slice(-2) + "/" + date1.getFullYear();
      var endDate = ("0" + date2.getDate()).slice(-2) + "/" + ("0" + (date2.getMonth() + 1)).slice(-2) + "/" + date2.getFullYear();

      let start = this.GetFormattedDate(startDate);
      let end = this.GetFormattedDate(endDate);
      let timeRange = {
        startDate: start,
        endDate: end,
        // startTime: '00:00',
        // endTime: '00:00'
      }

      this.dateRangeShow = startDate + ' - ' + endDate
      if (type == 'deposit') {
        this.getReportDeposit(timeRange);

      } else if (type == 'withdraw') {
        console.log('get withdtaw');
        this.getReportWithdraw(timeRange)
      }
    } else if (value == 'lastweek') {
      let now = new Date()

      var firstday = (now.getDate() - now.getDay()) - 7;
      var date1 = new Date(now.setDate(firstday));
      var lastday = date1.getDate() + 6;
      var date2 = new Date(now.setDate(lastday));
      var startDate = ("0" + date1.getDate()).slice(-2) + "/" + ("0" + (date1.getMonth() + 1)).slice(-2) + "/" + date1.getFullYear();
      var endDate = ("0" + (date2.getDate() + 1)).slice(-2) + "/" + ("0" + (date2.getMonth() + 1)).slice(-2) + "/" + date2.getFullYear();
      // $("#startdate").val(GetFormattedDate(startDate));
      // $("#todate").val(GetFormattedDate(endDate));
      let start = this.GetFormattedDate(startDate);
      let end = this.GetFormattedDate(endDate);
      let timeRange = {
        startDate: start,
        endDate: end,
      }

      this.dateRangeShow = startDate + ' - ' + endDate
      if (type == 'deposit') {
        this.getReportDeposit(timeRange);

      } else if (type == 'withdraw') {
        console.log('get withdtaw');
        this.getReportWithdraw(timeRange)
      }
    } else if (value == 'month') {
      var now = new Date();

      var date1 = new Date(now.getFullYear(), now.getMonth(), 1);
      var date2 = new Date(now.getFullYear(), now.getMonth() + 1);
      var startDate = ("0" + date1.getDate()).slice(-2) + "/" + ("0" + (date1.getMonth() + 1)).slice(-2) + "/" + date1.getFullYear();
      var endDate = ("0" + date2.getDate()).slice(-2) + "/" + ("0" + (date2.getMonth() + 1)).slice(-2) + "/" + date2.getFullYear();
      let start = this.GetFormattedDate(startDate);
      let end = this.GetFormattedDate(endDate);
      let timeRange = {
        startDate: start,
        endDate: end,

      }

      this.dateRangeShow = startDate + ' - ' + endDate
      if (type == 'deposit') {
        this.getReportDeposit(timeRange);

      } else if (type == 'withdraw') {
        console.log('get withdtaw');
        this.getReportWithdraw(timeRange)
      }
    } else if (value == 'lastmonth') {
      var now = new Date();

      var date1 = new Date(now.getFullYear(), now.getMonth() - 1);
      var date2 = new Date(now.getFullYear(), now.getMonth(), 1);
      var startDate = ("0" + date1.getDate()).slice(-2) + "/" + ("0" + (date1.getMonth() + 1)).slice(-2) + "/" + date1.getFullYear();
      var endDate = ("0" + date2.getDate()).slice(-2) + "/" + ("0" + (date2.getMonth() + 1)).slice(-2) + "/" + date2.getFullYear();
      // $("#startdate").val(GetFormattedDate(startDate));
      // $("#todate").val(GetFormattedDate(endDate));
      let start = this.GetFormattedDate(startDate);
      let end = this.GetFormattedDate(endDate);
      let timeRange = {
        startDate: start,
        endDate: end,

      }

      this.dateRangeShow = startDate + ' - ' + endDate
      if (type == 'deposit') {
        this.getReportDeposit(timeRange);

      } else if (type == 'withdraw') {
        console.log('get withdtaw');
        this.getReportWithdraw(timeRange)
      }
    }


  }

  GetFormattedDate(txtDate) {
    var month = txtDate.split('/')[1];
    var day = txtDate.split('/')[0];
    var year = txtDate.split('/')[2];
    return year + "-" + month + "-" + day;
  }


  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  back() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
  }

  copyPhone(phone) {
    this.clipboard.copy(phone);
    this.alertService.success('คัดลอกสำเร็จ', this.options);

  }

  showDetail(id) {
    console.log(id);
    (this.isShow === false || this.id != id) ? this.isShow = true : this.isShow = false;
    this.id = id;

  }

  getReportDeposit(time) {
    // this.setTimeRange('today', 'deposit')
    this.isLoading = true

    this.walletService.getReportDeposit(this.token, time).then((res: any) => {
      if (res.code == 0) {
        if (res.result.length > 0) {
          this.noData = false
          this.depositList = res.result
        } else {

        }
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.noData = true;
        this.alertService.error(res.message, this.options)
      }

      this.isLoading = false
    })
  }

  getReportWithdraw(time) {
    // this.setTimeRange('today', 'withdraw')
    //SUCESS กับ MANUAL = สำเร็จ
    //REJECT = ยกเลิก 
    //ที่เหลือรอดำเนินการ
    this.isLoading = true;
    this.walletService.getReportWithdraw(this.token, time).then((res: any) => {
      console.log(res);

      if (res.code == 0) {
        if (res.result.length > 0) {
          this.noData = false
          this.withdrawList = res.result
        } else {
          this.noData = true;

        }
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.noData = true;
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }

  changeTimeSetDeposit(event) {
    console.log(this.timeSet);
    this.setTimeRange(this.timeSet, 'deposit')
  }

  changeTimeSetWithdraw(event) {
    console.log(this.timeSet);
    this.setTimeRange(this.timeSet, 'withdraw')
  }

  clickDeposit() {
    this.isShowDeposit = true
    this.setTimeRange(this.timeSet, 'deposit')
  }

  clickWithdraw() {
    this.isShowDeposit = false
    this.setTimeRange(this.timeSet, 'withdraw')
  }

}
