<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">ถอนเงิน</li>
      </ol>
    </nav>

    <div class="card bg-template shadow mt-4 h-100">

      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-3 text-right">
            {{profile.username}}
          </div>

          <div class="col">
            <p class="text-mute">รายได้</p>
            <h4 class="mb-0 font-weight-normal"> {{profile.point | number: '1.2-2'}} </h4>
          </div>

        </div>
      </div>
    </div>
    <!-- <div class="mt-3">
      <span class="text-danger mb-3" style="font-size: 18px; font-weight: 500;">*คำเตือน</span> <br>
      คุณต้องทำยอดอย่างน้อย <span class="text-danger"
        style=" font-size: 16px; font-weight: bold; text-decoration: underline;">
        {{withdrawCondition.amountCanWithdraw | number: '1.2-2'}} บาท
      </span>
      เพื่อถอนเงินจาก เครดิต
    </div> -->

    <!-- <div class="row">
      <div class="col mt-4">
        <h5>บัญชีรับเงิน</h5>
      </div>
    </div> -->

    <div class="container" *ngIf="flag">
      <div class="row pt-3 pb-3">

        <div class="col ">
          <form [formGroup]="amountForm" class="form-signin mt-3 ">

            <div class="mt-3 mb-2 text-mute">ระบุจำนวนเงิน</div>
            <div class="form-group">
              <input type="text" name="inputamount" formControlName="amount"
                class="form-control form-control-lg text-left" [(ngModel)]="amount" placeholder="จำนวนเงิน" required
                autofocus>
            </div>
            <div style="color: red; font-size: 12px;">
              <div>จำนวนถอนขั้นต่ำ 1 บาท</div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="text-danger mt-3" *ngIf="!flag && profile.point >= 0.5">*วันนี้คุณได้ทำการกดถอนไปแล้ว</div>
    <div class="text-danger mt-3" *ngIf="!flag && profile.point < 0.5">*ถอนขั้นต่ำ 0.5 บาท</div>


    <div style="text-align: center;
    margin-top: 12%;">
      <button class="btn btn-lg btn-rounded shadow btn_login" [disabled]="isDisableWithdraw"
        (click)="withdraw()">ยืนยันการถอนเงิน</button>
    </div>
  </div>
</div>
<!-- page content ends -->
<!-- </div>
    </div> -->
<!-- </div> -->
<!-- wrapper ends -->
<app-loading-page [isLoading]="isLoading"></app-loading-page>
