import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { AlertService } from '../_alert';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-deposit-truemoney-page',
  templateUrl: './deposit-truemoney-page.component.html',
  styleUrls: ['./deposit-truemoney-page.component.scss']
})
export class DepositTruemoneyPageComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;
  token;
  prefix;
  profile;
  bankDeposit;
  logo;
  isDisableChshback = true;
  isLoading = false;
  public truemoney = []


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header: any = document.querySelector('.header');

    const pos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 30) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private clipboard: Clipboard,
    private ref: ChangeDetectorRef
  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit(): void {
    this.getPrefix()
    this.getTrueMoney()
  }


  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  back() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
  }

  copyPhone(phone) {
    this.clipboard.copy(phone);
    this.alertService.success('คัดลอกสำเร็จ', this.options);

  }

  getTrueMoney() {
    this.isLoading = true
    this.walletService.getTrueMoney(this.token).then((res: any) => {
      if (res.code == 0) {
        this.truemoney = res.result
        console.log(this.truemoney);
        this.ref.detectChanges();
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }
}
