import { WalletStorageService } from './../../service/wallet-storage.service';
import { AlertService } from './../_alert/alert.service';
import { ChangeDetectorRef, Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-link-get-point-page',
  templateUrl: './link-get-point-page.component.html',
  styleUrls: ['./link-get-point-page.component.scss']
})
export class LinkGetPointPageComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;
  token;
  prefix;
  profile;
  logo;
  isDisableCoupon = true;
  promotionList = []
  modalRef: BsModalRef;
  imgPreview;
  link = ''
  linkShare;
  isLoading = false;


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header: any = document.querySelector('.header');

    const pos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 30) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private walletStorageService: WalletStorageService,
    private modalService: BsModalService,
    private clipboard: Clipboard,
    private ref: ChangeDetectorRef

  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
  }

  openModal(template: TemplateRef<any>, img) {
    this.imgPreview = img
    this.modalRef = this.modalService.show(template);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit() {
    const dom: any = document.querySelector('body');
    dom.classList.remove('sidemenu-open');
    dom.classList.remove('menuactive');
    this.getProfile()
    this.getPrefix()
    this.getLink()
  }


  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  getProfile() {
    this.walletService.getProfile(this.token).then((res: any) => {
      if (res.code == 0) {
        this.profile = res.result;
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
    })
  }

  getLink() {
    this.isLoading = true
    this.walletService.getAffiliate(this.token).then((res: any) => {
      if (res.code == 0) {
        this.link = res.result.link
        this.ref.detectChanges();
        const test = ':'
        this.splitString(this.link, test)
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }

  splitString(stringToSplit, separator) {
    const arrayOfStrings = stringToSplit.split(separator)
    console.log('The original string is: ', stringToSplit)
    console.log('The separator is: ', separator)
    const link = arrayOfStrings.join('%3a')
    this.splitString2(link, '/')
    // console.log(link);

  }

  splitString2(stringToSplit, separator) {
    const arrayOfStrings = stringToSplit.split(separator)

    console.log('The original string is: ', stringToSplit)
    console.log('The separator is: ', separator)
    this.linkShare = arrayOfStrings.join('%2f')
  }

  copyLink() {
    this.clipboard.copy(this.link);
    this.alertService.success('คัดลอกลิงก์ สำเร็จ', this.options)

  }

  get facebookShareLink() {
    return 'https://www.facebook.com/sharer.php?u=' + this.linkShare
  }

  get lineShareLink() {
    return 'https://lineit.line.me/share/ui?url=' + this.linkShare
  }

  back() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
  }

}

