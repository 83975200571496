import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WalletStorageService {

  public amount: string;
  constructor() { }

  get amountDecimal(): string {
    return this.amount;
  }
  set amountDecimal(val: string) {
    this.amount = val;
  }

}

