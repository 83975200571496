<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">ฝากเงินออโต้</li>
      </ol>
    </nav>

    <div class="card bg-template shadow mt-4 h-200">

      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-3">
            บัญชีรับโอนเงิน
          </div>

          <div class="col-auto">
            <figure class="avatar avatar-60"><img style="width: 100%;" src="../../assets/images/bussiness-man.svg"
                alt=""></figure>
          </div>
          <div class="col pl-0 align-self-center">
            <p class=" small">{{bankDeposit.bankName}}</p>
            <h6 class="mb-1">ชื่อบัญชี {{bankDeposit.accountName}}</h6>
            <h6 class="mb-1">{{bankDeposit.accountNumber}}</h6>
            <button class="btn" style="border-color: #fff; color: #fff;" (click)="copyBank()">คัดลอกเลขบัญชี</button>

            <!-- <p class="text-mute small">00512945620</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <span class="text-danger mb-3" style="font-size: 18px; font-weight: 500;">*คำเตือน</span> <br> ต้องใช้บัญชี <span
        class="text-danger" style="font-weight: bold;"> {{profile.accountNumber}}
        ชื่อบัญชี {{profile.name}} </span>
      ในการฝากเงินเท่านั้น
    </div>

    <div style="text-align: center;
    margin-top: 12%;">
      <button class="btn btn-lg btn-rounded shadow btn_login" (click)="back()">ตรวจสอบยอดเงิน</button>
    </div>
  </div>
</div>
<!-- page content ends -->
<!-- </div>
    </div> -->
<!-- </div> -->
<!-- wrapper ends -->
<app-loading-page [isLoading]="isLoading"></app-loading-page>
