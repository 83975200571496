import { AlertService } from './../../_alert/alert.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  logo = ''
  prefix = ''
  line = ''
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  token;
  checkAuthPrefix: any;
  isMobile: any;

  constructor(public router: Router,
    private walletService: WalletService,
    private alertService: AlertService,
    private route: ActivatedRoute) {
    //   this.router.events.subscribe(val => { });
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');
  }

  ngOnInit() {
    this.getPrefix();
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    event.stopPropagation();
    dom.classList.toggle('sidemenu-open');
    dom.classList.toggle('menuactive');
  }
  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      if (res.code == 0) {
        this.logo = res.result.logo;
        this.line = res.result.line
        this.checkAuthPrefix = res.result
        let url
        if (this.checkAuthPrefix.product == "SPORT_BOOK") {
          url = 'link-sportbook'
        } else {
          url = 'linkGame'
        }
        this.walletService.get_game_path(this.token, url).then((res: any) => {
          if (res.code == 0) {
            this.checkAuthPrefix.urlMobileProduct = res.result.urlMobileProduct
            this.checkAuthPrefix.urlWebProduct = res.result.urlWebProduct
            setTimeout(() => {
            }, 1000);
          }
        })
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  clickGame() {
    if (this.isMobile) {
      window.open(this.checkAuthPrefix.urlMobileProduct, '_blank');
    } else {
      window.open(this.checkAuthPrefix.urlWebProduct, '_blank');
    }
  }

  logout() {
    localStorage.setItem('token', '')
    this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
  }

  // changeLang(language: string) {
  //     this.translate.use(language);
  // }
}
