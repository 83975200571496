<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">ฝากเงินออโต้</li>
      </ol>
    </nav>
    <div class="mt-3">
      <h3>กรอกจำนวนเงิน</h3>
      <form [formGroup]="amountForm" class="form-signin mt-3 ">
        <div class="form-group">
          <input type="text" name="inputamount" formControlName="amount"
            class="form-control form-control-lg text-center" [(ngModel)]="amount" placeholder="จำนวนเงิน" required
            autofocus>
        </div>
        <div style="color: red;">
          <div>ขั้นต่ำ 1 บาท</div>
        </div>

        <div class="row" style="margin-left: 1%;">
          <button class="col-3 block_amount" (click)="clickAmount('50')">
            50
          </button>

          <button class="col-3 block_amount" (click)="clickAmount('100')">
            100
          </button>

          <button class="col-3 block_amount" (click)="clickAmount('300')">
            300
          </button>
        </div>

        <div class="row" style="margin-left: 1%;">
          <button class="col-3 block_amount" (click)="clickAmount('500')">
            500
          </button>

          <button class="col-3 block_amount" (click)="clickAmount('1000')">
            1,000
          </button>

          <button class="col-3 block_amount" (click)="clickAmount('5000')">
            5,000
          </button>
        </div>
        <div style="text-align: center;
        margin-top: 12%;">
          <button class="btn btn-lg btn-rounded shadow btn_login" (click)="clickCreateDeposit()"
            [disabled]="isDisableAmount">ทำรายการ</button>
        </div>
      </form>
    </div>

  </div>
</div>
<app-loading-page [isLoading]="isLoading"></app-loading-page>
