import { Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { AlertService } from '../_alert';

// @Pipe({ name: 'safeHtml' })
// export class SafeHtmlPipe implements PipeTransform {
//   constructor(private sanitized: DomSanitizer) { }
//   transform(value) {
//     return this.sanitized.bypassSecurityTrustHtml(value);
//   }
// }
@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss']
})


export class SignupPageComponent implements OnInit {
  @ViewChild('dataContainer') dataContainer: ElementRef;

  stepRegister = 1;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";
  passwordPattern = '/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/'
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  phone: string = '';
  password: string = ''
  bank = {
    bankName: '',
    bankNumber: '',
    name: '',
    lastname: ''
  }

  step3 = {
    password: '',
    line: '',
    ref: '',
    etc: '',
    bonus: 'true',
    captcha: '',
    captchaImg: ''
  }
  prefix;
  logo = ''
  selectBank = []

  mobileForm = new FormGroup({
    mobile: new FormControl(['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]),
  });

  bankForm = new FormGroup({
    bankName: new FormControl(['', [Validators.required]]),
    bankNumber: new FormControl(['', [Validators.required]]),
    name: new FormControl(['', [Validators.required]]),
    lastname: new FormControl(['', [Validators.required]]),
  })

  step3Form = new FormGroup({
    password: new FormControl(['', [Validators.required]]),
    line: new FormControl(['', [Validators.required]]),
    ref: new FormControl(['', [Validators.required]]),
    etc: new FormControl(['', [Validators.required]]),
    bonus: new FormControl(['', [Validators.required]]),
    captcha: new FormControl(['', [Validators.required]]),
    captchaImg: new FormControl(['', [Validators.required]]),

  })

  isDisableBank = true;
  isDisableStep3 = true;
  isLoading = false


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService
  ) {
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    this.bankForm.valueChanges.subscribe(value => {
      console.log(value);
      if (value.bankName.length > 0 && value.bankNumber.length >= 10 && value.name.length > 0 && value.lastname.length > 0) {
        this.isDisableBank = false
      } else {
        this.isDisableBank = true
      }
    })

    this.step3Form.valueChanges.subscribe(value => {
      console.log(value);
      this.password = value.password

      if (this.password.match('^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{8,}$')) {
        console.log('ผ่าน');
        if (value.password.length >= 8 && value.ref.length > 1 && value.captcha.length == 4) {
          this.isDisableStep3 = false
        } else {
          this.isDisableStep3 = true
        }
      } else {
        this.isDisableStep3 = true
        console.log('ไม่ผ่าน');
      }

    })
  }

  ngOnInit(): void {
    this.getPrefix();
    this.getBank();
  }

  getPrefix() {

    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }


  getBank() {
    this.isLoading = true
    this.walletService.getBank().then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        this.selectBank = res.result
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }


  onNext(step) {
    console.log(step);

    if (step == 1) {
      if (this.phone.match('^0[0-9]{9}$')) {
        this.isLoading = true;
        const body = {
          prefix: atob(this.prefix),
          type: "PHONE",
          value: this.phone
        }
        this.walletService.checkRegister(body).then((res: any) => {
          if (res.code == 0) {
            console.log(res);
            this.stepRegister = step + 1;
          } else {
            this.alertService.error(res.message, this.options)
          }
          this.isLoading = false
        })
      } else {
        this.alertService.error('กรุณาระบุเบอร์โทรศัพท์', this.options)
      }
    } else if (step == 2) {
      this.isLoading = true
      const body = {
        prefix: atob(this.prefix),
        type: "ACCOUNT",
        value: this.bank.bankNumber
      }
      this.walletService.checkRegister(body).then((res: any) => {
        if (res.code == 0) {
          this.stepRegister = step + 1;
          this.getCaptcha();
        } else {
          this.alertService.error(res.message, this.options)
        }
        this.isLoading = false
      })
    }
  }

  getCaptcha() {
    this.walletService.getCaptcha(atob(this.prefix)).then((res: any) => {
      console.log(typeof res.result);
      this.step3.captchaImg = ''
      if (typeof res.result != 'string') {
        this.getCaptcha();
      } else {
        if (res.code == 0) {
          this.step3.captchaImg = res.result
          this.dataContainer.nativeElement.innerHTML = this.step3.captchaImg;
        }
      }

    })
  }

  onRegister() {
    console.log('phone', this.phone);
    console.log('bank', this.bank);
    console.log('step3', this.step3);

    this.isLoading = true;
    const body = {
      "prefix": atob(this.prefix),
      "tel": this.phone,
      "password": this.step3.password,
      "firstName": this.bank.name,
      "lastName": this.bank.lastname,
      "accountNumber": this.bank.bankNumber,
      "bankName": this.bank.bankName,
      "line": this.step3.line ? this.step3.line : '',
      "recommend": this.step3.ref,
      "reference": "",
      "captcha": this.step3.captcha,
      "isBonus": this.step3.bonus == 'true' ? true : false, // true or false
      "firstNameEN": "",
      "lastNameEN": "",
      "recommendOther": ""
    }
    this.walletService.Register(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        localStorage.setItem('token', res.result.token);

        this.alertService.success(res.message, this.options)
        setTimeout(() => {
          this.isLoading = false

          this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
        }, 3000);

      } else {
        this.alertService.error(res.message, this.options)
        this.isLoading = false

      }
    })

  }

  back() {
    if (this.stepRegister > 1) {
      this.stepRegister = this.stepRegister - 1
      console.log(this.stepRegister);


    } else {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });

    }

  }

}
