<div class="login-page">
  <div class="wrapper vh-100" [ngClass]="{'blur': isLoading}" style="overflow: auto;">
    <div class="mt-3 ml-3" (click)="back()">
      < ล็อกอิน</div> <div class="row no-gutters h-100 login-row" *ngIf="stepRegister ==1">
        <div class="col align-self-center px-3 text-center">
          <br>
          <img src="{{logo}}" alt="logo" class="logo-small">
          <h3 class="mt-3">สมัครสมาชิก</h3>
          <h5 class="mt-2"> 1 / 3</h5>
          <form [formGroup]="mobileForm" class="form-signin mt-3 ">
            <div class="form-group">
              <input type="text" formControlName="mobile" name="inputPhone"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                [(ngModel)]="phone" class="form-control form-control-lg text-center" placeholder="หมายเลขโทรศัพท์"
                maxlength="10" [ngClass]="phone.length != 10 ? 'req': ''" required autofocus>
              <div class="text-left text-danger" style="font-size: 12px;" *ngIf=" phone.length != 10">
                *กรุณาระบุเบอร์โทรศัพท์
              </div>
            </div>

            <div style="margin-top: 30%;">
              <button [disabled]="phone.length != 10" (click)="onNext(1)"
                class="btn btn-lg btn-rounded shadow btn-block btn_login">ต่อไป</button>
            </div>

          </form>
        </div>
    </div>

    <div class="row no-gutters h-100 login-row" *ngIf="stepRegister == 2">
      <div class="col align-self-center px-3 text-center">
        <br>
        <img src="{{logo}}" alt="logo" class="logo-small">
        <h3 class="mt-3">สมัครสมาชิก</h3>
        <h5 class="mt-2"> 2 / 3</h5>
        <form [formGroup]="bankForm" class="form-signin mt-3 ">
          <div class="form-group">
            <select name="inputBankName" formControlName="bankName" class=" form-control text-center"
              [(ngModel)]="bank.bankName" [ngClass]="bank.bankName == '' ? 'req': ''" required autofocus>
              <option value="">กรุณาเลือกธนาคาร</option>
              <option *ngFor="let item of selectBank" [value]="item.shortName">{{ item.name.th }}</option>
            </select>
            <div class="text-left text-danger" style="font-size: 12px;" *ngIf="bank.bankName == ''">
              *กรุณาเลือกธนาคาร
            </div>
          </div>

          <div class="form-group">
            <input type="text" name="inputBankNumber" formControlName="bankNumber"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              [(ngModel)]="bank.bankNumber" class="form-control form-control-lg text-center" placeholder="หมายเลขบัญชี"
              [ngClass]="bank.bankNumber.length <= 8 ? 'req': ''" required autofocus>
            <div class="text-left text-danger" style="font-size: 12px;" *ngIf="bank.bankNumber.length <= 8">
              *กรุณาระบุเลขบัญชี
            </div>
          </div>
          <div class="form-group">
            <input type="text" name="inputBankName" formControlName="name"
              oninput="this.value = this.value.replace(/[^a-z A-Z.]/g, '').replace(/(\..*)\./g, '$1');"
              [(ngModel)]="bank.name" class="form-control form-control-lg text-center" placeholder="ชื่อบัญชี"
              maxlength="30" [ngClass]="bank.name == '' ? 'req': ''" required autofocus>
            <div class="text-left text-danger" style="font-size: 12px;" *ngIf="bank.name == ''">
              *กรุณาระบุชื่อ
            </div>
          </div>
          <div class="form-group">
            <input type="text" name="inputBankLastname" formControlName="lastname"
              oninput="this.value = this.value.replace(/[^a-z A-Z.]/g, '').replace(/(\..*)\./g, '$1');"
              [(ngModel)]="bank.lastname" class="form-control form-control-lg text-center" placeholder="นามสกุล"
              maxlength="30" [ngClass]="bank.lastname == '' ? 'req': ''" required autofocus>
            <div class="text-left text-danger" style="font-size: 12px;" *ngIf="bank.lastname == ''">
              *กรุณาระบุนามสกุล
            </div>
          </div>
          <div style="margin-top: 30%;">
            <button [disabled]="isDisableBank " (click)="onNext(2)"
              class="btn btn-lg btn-rounded shadow btn-block btn_login">ต่อไป</button>
          </div>

        </form>
      </div>
    </div>

    <div class="row no-gutters h-100 login-row" *ngIf="stepRegister == 3">
      <div class="col align-self-center px-3 text-center">
        <br>
        <img src="{{logo}}" alt="logo" class="logo-small">
        <h3 class="mt-3">สมัครสมาชิก</h3>
        <h5 class="mt-2"> 3 / 3</h5>
        <form [formGroup]="step3Form" class="form-signin mt-3 ">

          <div class="form-group">
            <show-hide-password size="lg" [btnOutline]="false">
              <input type="password" name="inputPassword" formControlName="password" [(ngModel)]="step3.password"
                class="form-control form-control-lg text-center" placeholder="รหัสผ่าน"
                [ngClass]="step3.password.length < 8 ? 'req': ''" required autofocus>
            </show-hide-password>
            <p style="font-size: 12px; text-align: left;" class="text-danger">*รหัสผ่านต้องมีA-Zหรือa-zและตัวเลข
              อย่างน้อย8ตัว</p>
          </div>
          <div class="form-group">
            <input type="text" name="inputLine" formControlName="line" [(ngModel)]="step3.line"
              class="form-control form-control-lg text-center" placeholder="ไลน์ไอดี" required autofocus>
          </div>

          <div class="form-group" [ngClass]="step3.ref.length < 1 ? 'req': ''">
            <select name="inputBankName" formControlName="ref" class=" form-control text-center" [(ngModel)]="step3.ref"
              [ngClass]="step3.ref.length < 1 ? 'req': ''" required autofocus>
              <option value="">รู้จักเราจากช่องทางใด</option>
              <option value="FACEB0OK">Facebooks</option>
              <option value="LINE">Line</option>
              <option value="GOOGLE">Google</option>
              <option value="YOUTUBE">Youtube</option>
              <option value="IG">Instagram (ig)</option>
              <option value="TIKTOK">TikTok</option>
              <option value="SMS">SMS</option>
              <option value="FRIEND">เพื่อนแนะนำ</option>
              <option value="EXPERT">เชียน</option>
              <option value="OTHER">โฆษค่าอื่น ๆ</option>
            </select>
            <div class="text-left text-danger" style="font-size: 12px;" *ngIf="step3.ref.length < 1">
              *กรุณาเลือกช่องทาง
            </div>
          </div>

          <div class="form-group">
            <input type="text" name="inputEtc" formControlName="etc" [(ngModel)]="step3.etc"
              class="form-control form-control-lg text-center" placeholder="เพิ่มเติม" required autofocus>
          </div>
          <div class="text-left">คุณต้องการรับโบนัสจากการเติมเงินหรือไม่</div>

          <div class="form-group" style="margin-top: 20px;">
            <input type="radio" name="bonus" formControlName="bonus" [(ngModel)]="step3.bonus" value='true'>
            รับโบนัส
            <input type="radio" name="bonus" formControlName="bonus" [(ngModel)]="step3.bonus" value='false'>
            ไม่รับโบนัส
          </div>

          <div class="form-group">
            <div class="col" #dataContainer></div>
            <div (click)="getCaptcha()">
              <i class="fas fa-redo text-danger"><span style="margin-left: 10px;">อ่านไม่ออก
                  กรุณารีเซ็ตรหัสภาพใหม่</span></i>
            </div>

          </div>
          <div class="form-group">
            <input type="text" name="inputCaptcha" formControlName="captcha"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              [(ngModel)]="step3.captcha" class="form-control form-control-lg text-center" placeholder="รหัสภาพ"
              maxlength="4" [ngClass]="step3.captcha.length < 4 ? 'req': ''" required autofocus>
          </div>


          <div style="margin-top: 10%; margin-bottom: 10%;">
            <button [disabled]="isDisableStep3" (click)="onRegister()"
              class="btn btn-lg btn-rounded shadow btn-block btn_login">สมัครสมาชิก</button>
          </div>

        </form>
      </div>
    </div>


  </div>
</div>

<app-loading-page [isLoading]="isLoading"></app-loading-page>
