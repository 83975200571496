<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" id="header-container"></app-header>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">เอนเตอร์เทน</li>
      </ol>
    </nav>
    <div style="text-align: center; color: #000; z-index: 10; position: relative; height: 46vh;">
      <iframe style="padding: 1rem; height: -webkit-fill-available;" width="90%" [src]='safeURL' frameborder="0"
        allowfullscreen *ngIf="safeURL"></iframe>
    </div>
    <hr style="margin: 1rem;">
    <div style="text-align: center; color: #000; z-index: 10; position: relative;">
      <div style="padding: 0rem 0rem 1rem 1rem; font-size: 16px; text-align: left;">
        ดูเพิ่มเติม
      </div>
    </div>
    <div class="row"
      style="margin: 15px 0px 15px 30px; display: flex; flex-wrap: wrap; text-align: center; justify-content: space-evenly; color: #000; font-size: 12px; position: relative;">
      <div *ngFor="let item of movieList" style="max-width: 105px; margin: 0.5em; position: relative; height: 230px;"
        (click)="onSelect(item.id)">
        <div
          style="position: absolute; top: 5px; left: 5px; width: 34px; height: 16px; background: #FF0000; border-radius: 3px; padding: 1px; font-size: 10px;">
          <img src="assets/svg/star.svg"> {{item.score}}</div>
        <img class="img-preview" src={{item.image}}>
        <div>{{item.title}}</div>
        <div style="font-size: 10px; color: #B3B3B3;">{{item.sound}}</div>
        <div
          [ngClass]="{'fullhd': item.resolution == 'FullHD', 'hd': item.resolution == 'HD', 'zm': item.resolution == 'ZM'}"
          style="position: absolute; right: 5px; bottom: 70px; width: 38px; height: 16px; border-radius: 3px; padding: 1px; font-size: 10px;">
          {{item.resolution}}
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row" style="margin: 15px; display: flex; flex-wrap: wrap; text-align: center; justify-content: space-evenly;">
        <div *ngFor="let item of movieList" style="max-width: 140px; margin: 0.5em;" (click)="onSelect(item.id)">
            <img class="img-preview" src={{item.image}}>
            <div style="color: #fff; font-size: 12px;">{{item.title}}</div>
        </div>
    </div> -->

  <app-loading-page [isLoading]="isLoading"></app-loading-page>
