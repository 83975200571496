<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" id="header-container"></app-header>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">แชร์ลิงก์ให้เพื่อน</li>
      </ol>
    </nav>
    <div class="container">
      <div class="mt-3">
        <div style="font-size: 1.25rem;">แชร์ลิงก์ให้เพื่อน รับโบนัสพอยต์ฟรี</div>
        <div class="box  mt-3 p-3">
          <div class="row">
            <div class="col">
              <div>แชร์ลิงก์ของคุณ</div>
            </div>
          </div>
          <div class="row">
            <div class=" col pr-0">
              <input type="text" name="inputPhone" [(ngModel)]="link" class="form-control form-control-lg text-center"
                required autofocus>
            </div>
            <div class="col-auto pl-0">
              <button class="btn btn-lg btn-rounded shadow btn-block" style="font-size: 14px; 
                  color: #fff; background-color: #22a7f0; height: 48px; width: 80px;"
                (click)="copyLink()">คัดลอก</button>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <a target="_blank" href="{{facebookShareLink}}">
              <button class="btn btn-lg btn-rounded shadow btn-block"
                style="background-color: #3a559f ; color: #fff;">Facebook</button>

            </a>

          </div>
          <div class="col">

            <a target="_blank" href="{{lineShareLink}}">
              <button class="btn btn-lg btn-rounded shadow btn-block"
                style="background-color: #00c202 ; color: #fff;">Line</button>

            </a>


          </div>
        </div>


      </div>
    </div>
  </div>
</div>
<app-loading-page [isLoading]="isLoading"></app-loading-page>
