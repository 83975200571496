<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container" *ngIf="step ==1">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">ใช้รหัสคูปอง</li>
      </ol>
    </nav>

    <div class="container">
      <div class="mt-3">
        <h3 class="text-center">กรอกเลขคูปอง</h3>
        <form [formGroup]="couponForm" class="form-signin mt-3 ">
          <div class="form-group">
            <input type="text" name="inputamount" formControlName="coupon" maxlength="8"
              class="form-control form-control-lg text-center" [(ngModel)]="coupon" placeholder="จำนวนเงิน" required
              autofocus>
          </div>
          <!-- <div class="text-right" style="font-size: 14px;">สูงสุดไม่เกิน {{minMax.max | number:'1.0-2'}} บาท</div> -->

          <hr>
          <!-- <div class="text-left text-danger" style="font-size: 14px;">
            * กรุณาตรวจสอบรายการของคุณอีกครั้ง แล้วกด ยืนยันการทำรายการ เพื่อทำรายการ
          </div> -->

          <div style="text-align: center;
              margin-top: 12%;">
            <button class="btn btn-lg btn-rounded shadow btn_login" (click)="clickCoupon()"
              [disabled]="isDisableCoupon">ยืนยันการทำรายการ</button>
          </div>
        </form>
      </div>
    </div>

  </div>



</div>

<app-loading-page [isLoading]="isLoading"></app-loading-page>
