<!-- <div class="wrapper"  >
  <div class="circle"></div>
  <div class="circle"></div>
  <div class="circle"></div>
  <div class="shadow"></div>
  <div class="shadow"></div>
  <div class="shadow"></div>
  <span>Loading</span>
</div> -->

<div style="height: 100vh; width: 100%; background: rgba(9, 32, 88, 0.3); position: fixed; 
top: 0; margin: auto; padding-top: 50%; z-index: 10;" *ngIf="isLoading">
  <div class="loader" style="justify-content: center;">
    <div class="text">Loading</div>
    <div class="dots">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
