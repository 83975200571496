import { WalletStorageService } from './../../service/wallet-storage.service';
import { AlertService } from './../_alert/alert.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-deposit-coupon-page',
  templateUrl: './deposit-coupon-page.component.html',
  styleUrls: ['./deposit-coupon-page.component.scss']
})
export class DepositCouponPageComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;
  token;
  prefix;
  profile;
  bankDeposit;
  logo;
  isDisableCoupon = true;
  clickEvent;
  coupon = '';
  minMax;
  vpay;
  step = 1
  couponForm = new FormGroup({
    coupon: new FormControl(['', [Validators.required]])
  });

  isLoading = false

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header: any = document.querySelector('.header');

    const pos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 30) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private walletStorageService: WalletStorageService
  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    this.couponForm.valueChanges.subscribe(value => {

      if (value.coupon.length == 8) {
        this.isDisableCoupon = false
      } else {
        this.isDisableCoupon = true
      }
    })
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit() {
    const dom: any = document.querySelector('body');
    dom.classList.remove('sidemenu-open');
    dom.classList.remove('menuactive');
    this.getProfile()
    this.getPrefix()

  }

  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  getProfile() {
    this.isLoading = true
    this.walletService.getProfile(this.token).then((res: any) => {
      if (res.code == 0) {
        this.profile = res.result;
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }

  clickCoupon() {

    this.isLoading = true;
    const body = {
      code: this.coupon
    }
    this.walletService.useCoupon(this.token, body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        this.alertService.success(res.message, this.options);
        setTimeout(() => {
          this.isLoading = false
          this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
        }, 3000);
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.isLoading = false

        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
        this.isLoading = false

      }
    })
  }


  back() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
  }

}

