import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { AlertService } from '../_alert';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  userName: string = '';
  password: string = '';
  prefix;
  logo = ''

  // alert = {
  //   type: 'error',
  //   msg: 'error'
  // }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService
  ) {
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');
  }

  ngOnInit(): void {
    this.getPrefix();
  }

  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }


  onLoggedin() {
    const body = {
      "username": this.userName,
      "password": this.password,
      "prefix": atob(this.prefix)
    }
    this.walletService.login(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        localStorage.setItem('token', res.result.token);
        localStorage.setItem('prefix', res.result.profile.prefix);
        this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
    // this.router.navigate(['/signup']);
  }

  onRegister() {
    this.router.navigate(['/sign-up'], { queryParams: { prefix: this.prefix } });
  }

  onForgetPassword() {
    this.router.navigate(['/forget-password'], { queryParams: { prefix: this.prefix } });
  }

}
