<div class="container">


  <div class="row no-gutters">
    <div class="col text-left">
      <button class="btn  btn-link text-dark menu-btn" (click)="toggleSidebar()">
        <i class="material-icons">menu</i>
      </button>
    </div>
    <!-- <div class="col text-center">
    <img src="../assets/images/logo-header.png" alt="" class="header-logo" />
  </div> -->
    <div class=" text-right">
      <button style="width: 109px;
    height: 36px;
    font-size: 14px;
    margin-top: 13px;
    margin-left: 12px;
    float: right;
    line-height: normal;" class="btn btn-lg btn-rounded shadow btn-block btn_login"
        (click)="clickGame()">เข้าเล่นเกม</button>
    </div>

    <div class=" text-right">
      <button style="width: 109px;
    height: 36px;
    font-size: 14px;
    margin-top: 13px;
    margin-left: 12px;
    float: right;
    line-height: normal;" class="btn btn-lg btn-rounded shadow btn-block btn_login"
        (click)="logout()">ออกจากระบบ</button>
    </div>
  </div>
</div>
