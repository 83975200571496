import { WalletStorageService } from './../../service/wallet-storage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { AlertService } from '../_alert';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-deposit-decimal-info-page',
  templateUrl: './deposit-decimal-info-page.component.html',
  styleUrls: ['./deposit-decimal-info-page.component.scss']
})
export class DepositDecimalInfoPageComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;
  bankDepositDecimal;
  token;
  prefix;
  logo;
  countDown;
  test = 'dsfdsfds'
  isLoading = false
  // amount;
  constructor(
    private walletStorageService: WalletStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private clipboard: Clipboard

  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');
    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
  }


  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit() {
    const dom: any = document.querySelector('body');
    dom.classList.remove('sidemenu-open');
    dom.classList.remove('menuactive');
    this.getPrefix()
    this.createDepositDecimal();

  }

  handleEvent(event) {
    console.log(event)
    const end = 'หมดอายุแล้ว'
    if (event.action == 'done') {
      console.log('dsf')
      event.text = end
      console.log(event);


    }
  }


  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  createDepositDecimal() {
    this.isLoading = true
    const amount = this.walletStorageService.amount
    const body = {
      value: amount
    }
    this.walletService.createDepositDecimal(this.token, body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        this.bankDepositDecimal = res.result
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false

    })
  }

  back() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
  }

  copyBank() {
    this.clipboard.copy(this.bankDepositDecimal.accountNumber);
    this.alertService.success('คัดลอกสำเร็จ', this.options);

  }

}
