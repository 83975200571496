import { SuccessSendPasswordComponent } from './../success-send-password/success-send-password.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { AlertService } from '../_alert';

@Component({
  selector: 'app-forget-password-page',
  templateUrl: './forget-password-page.component.html',
  styleUrls: ['./forget-password-page.component.scss']
})
export class ForgetPasswordPageComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  phone: string = '';
  prefix;
  logo = ''
  mobileForm = new FormGroup({
    mobile: new FormControl(['', [Validators.required]]),
  });
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private matDialog: MatDialog
  ) {
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');
  }

  ngOnInit(): void {
    this.getPrefix()
  }

  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  forgetPassword() {
    const body = {
      prefix: atob(this.prefix),
      tel: this.phone
    }

    if (this.phone.match('^0[0-9]{9}$')) {
      this.walletService.forgetPassword(body).then((res: any) => {
        console.log(res);
        if (res.code == 0) {
          this.alertService.success(res.message, this.options)
          setTimeout(() => {
            this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
          }, 3000);
        } else {
          this.alertService.error(res.message, this.options)
        }

      })
    } else {
      this.alertService.error('กรุณาระบุเบอร์โทรศัพท์', this.options)

    }

  }

  back() {
    this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });

  }

}
