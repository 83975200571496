<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" id="header-container"></app-header>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">เอนเตอร์เทน</li>
      </ol>
    </nav>
    <div style="display: flex; justify-content: center; position: relative;">
      <img src={{detail.image}} style="z-index: 1; margin-top: 10%; max-height: 350px;">
      <div style="position: absolute;">
        <img src={{detail.image}} style="filter: blur(7px); width: 80vw; height: 318px;">
      </div>
    </div>
    <div style="text-align: center; z-index: 1; position: relative;">
      <div style="padding: 1rem; font-size: 24px; font-weight: 700; color: black">
        {{detail.title}}
      </div>
      <div>
        <span style="color: #B3B3B3; font-size: 16px;">{{detail.sound}} </span><img src="assets/svg/star.svg"><span
          style="color: #000; font-size: 16px;"> ({{detail.score}})</span>
      </div>
      <div>
        <span class="category" style="color: #B3B3B3; font-size: 12px;" *ngFor="let category of detail.category">
          {{category.title_category_eng}}
        </span>
      </div>
      <div style="margin: 1rem;">
        <button style="background: #186BFE; color: #fff; border-radius: 4px; height: 36px; border: none; width: 100%;"
          (click)="playMovie()"><img src="assets/svg/play.svg"> Play</button>
      </div>
      <div style="padding: 0px 1rem; font-size: 14px; color: #B3B3B3">
        {{detail.description}}
      </div>
      <hr style="margin: 1rem;">
    </div>
    <div style="text-align: center; color: black; z-index: 10; position: relative;">
      <div style="padding: 1rem; font-size: 16px; text-align: left;">
        ตัวอย่าง
      </div>
      <iframe style="padding: 1rem; width: 90%; height: 500px;" [src]='safeURL' frameborder="0"
        allowfullscreen></iframe>

      <!-- <video width="320" height="240" controls>
            <source src={{detail.youtube}}>
          </video> -->
    </div>
    <div class="row"
      style="margin: 15px 0px 15px 30px; display: flex; flex-wrap: wrap; text-align: center; justify-content: space-evenly; color: #000; font-size: 12px; position: relative;">
      <div *ngFor="let item of movieList" style="max-width: 140px; margin: 0.5em; position: relative; height: 220px;"
        (click)="onSelect(item.id)">
        <div
          style="position: absolute; top: 5px; left: 5px; width: 34px; height: 16px; background: #FF0000; border-radius: 3px; padding: 1px; font-size: 10px; z-index: 1;">
          <img src="assets/svg/star.svg"> {{item.score}}</div>
        <img class="img-preview" src={{item.image}}>
        <div>{{item.title}}</div>
        <div style="font-size: 10px; color: #B3B3B3;">{{item.sound}}</div>
        <div
          [ngClass]="{'fullhd': item.resolution == 'FullHD', 'hd': item.resolution == 'HD', 'zm': item.resolution == 'ZM'}"
          style="position: absolute; right: 5px; bottom: 60px; width: 38px; height: 16px; border-radius: 3px; padding: 1px; font-size: 10px;">
          {{item.resolution}}
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row" style="margin: 15px; display: flex; flex-wrap: wrap; text-align: center; justify-content: space-evenly;">
        <div *ngFor="let item of movieList" style="max-width: 140px; margin: 0.5em;" (click)="onSelect(item.id)">
            <img class="img-preview" src={{item.image}}>
            <div style="color: #fff; font-size: 12px;">{{item.title}}</div>
        </div>
    </div> -->

  <app-loading-page [isLoading]="isLoading"></app-loading-page>
