<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">ถอนเงิน</li>
      </ol>
    </nav>

    <div class="card bg-template shadow mt-4 h-100">

      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-3 text-right">
            {{profile.username}}
          </div>

          <div class="col">
            <p class="text-mute">Credit</p>
            <h4 class="mb-0 font-weight-normal"> {{profile.credit | number: '1.2-2'}} </h4>
          </div>

        </div>
      </div>
    </div>
    <div class="mt-3">
      <span class="text-danger mb-3" style="font-size: 18px; font-weight: 500;">*คำเตือน</span> <br>
      คุณต้องทำยอดอย่างน้อย <span class="text-danger"
        style=" font-size: 16px; font-weight: bold; text-decoration: underline;">
        {{withdrawCondition.amountCanWithdraw | number: '1.2-2'}} บาท
      </span>
      เพื่อถอนเงินจาก เครดิต
    </div>

    <div class="row">
      <div class="col mt-4">
        <h5>บัญชีรับเงิน</h5>
      </div>
    </div>

    <div class="container">
      <div class="row border-block pt-3 pb-3">
        <div class="col-auto">
          <figure class="avatar avatar-65"><img style="width: 100%;" src="{{bankImg}}" alt="">
          </figure>
        </div>
        <div class="col pl-0 align-self-center">
          <p class=" small">{{profile.bankName}}</p>
          <h6 class="mb-1">ชื่อบัญชี {{profile.name}}</h6>
          <h6 class="mb-1">{{profile.accountNumber}}</h6>
          <button class="btn" style="border-color: #fff; color: #fff; background-color: #3a9ef7;"
            (click)="copyBank()">คัดลอกเลขบัญชี</button>
        </div>
      </div>
    </div>

    <div style="text-align: center;
    margin-top: 12%;">
      <button class="btn btn-lg btn-rounded shadow btn_login" [disabled]="isDisableWithdraw"
        (click)="withdraw()">ยืนยันการถอนเงิน</button>
    </div>
  </div>
</div>
<!-- page content ends -->
<!-- </div>
    </div> -->
<!-- </div> -->
<!-- wrapper ends -->
<app-loading-page [isLoading]="isLoading"></app-loading-page>
