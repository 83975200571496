import { Pipe } from "@angular/core";

@Pipe({
    name: "statusWithdraw",
})
export class statusWithdraw {
    transform(i) {
        if (i == "CREATE") {
            return "รอดำเนินการ";
        }
        else if (i == "SUCCESS") {
            return "สำเร็จ";
        }
        else if (i == "MANUAL") {
            return "สำเร็จ";
        }
        else if (i == "REJECT") {
            return "ยกเลิก";
        }
        else {
            return "รอดำเนินการ"
        }


    }
}

