import { WalletStorageService } from './../../service/wallet-storage.service';
import { AlertService } from './../_alert/alert.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WalletService } from 'src/service/wallet.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-deposit-credit-card-page',
  templateUrl: './deposit-credit-card-page.component.html',
  styleUrls: ['./deposit-credit-card-page.component.scss']
})
export class DepositCreditCardPageComponent implements OnInit {

  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;
  token;
  prefix;
  profile;
  bankDeposit;
  logo;
  isDisableAmount = true;
  clickEvent;
  amount = '';
  minMax;
  vpay;
  step = 1
  isLoading = false;
  amountForm = new FormGroup({
    amount: new FormControl(['', [Validators.required]])
  });

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header: any = document.querySelector('.header');

    const pos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 30) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private alertService: AlertService,
    private walletStorageService: WalletStorageService
  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }

    this.amountForm.valueChanges.subscribe(value => {
      console.log(+(value.amount));

      if (+(value.amount) >= 1) {
        this.isDisableAmount = false
      } else {
        this.isDisableAmount = true
      }
    })
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit() {
    const dom: any = document.querySelector('body');
    dom.classList.remove('sidemenu-open');
    dom.classList.remove('menuactive');
    this.getProfile()
    this.getPrefix()
    this.getMinMax()

  }

  getPrefix() {
    const body = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        console.log(res.result.logo);
        this.logo = res.result.logo
      } else {
        this.alertService.error(res.message, this.options)
      }

    })
  }

  getProfile() {
    this.isLoading = true
    this.walletService.getProfile(this.token).then((res: any) => {
      if (res.code == 0) {
        this.profile = res.result;
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }

  clickCreateAskmepay() {
    this.isLoading = true
    const body = {
      amount: this.amount
    }
    this.walletService.getInitCreditCard(this.token, body).then((res: any) => {
      console.log(res);
      if (res.code == 0) {
        this.step = 2
        this.vpay = res.result
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
      this.isLoading = false
    })
  }

  getMinMax() {
    this.walletService.getMinMaxCredit(this.token).then((res: any) => {
      if (res.code == 0) {
        this.minMax = res.result
      } else if (res.code == 1005) {
        localStorage.removeItem('token')
        this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
      } else {
        this.alertService.error(res.message, this.options)
      }
    })
  }

  downloadQRCode(img) {
    const linkSource = img;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = 'QRCode';
    downloadLink.click();
  }

  back() {
    this.router.navigate(['/dashboard'], { queryParams: { prefix: this.prefix } });
  }

}

