import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { WalletService } from 'src/service/wallet.service';

@Component({
  selector: 'app-movie-detail',
  templateUrl: './movie-detail.component.html',
  styleUrls: ['./movie-detail.component.scss']
})
export class MovieDetailComponent implements OnInit {
  isLoading = false;
  prefix: any = '';
  id = '';
  detail: any;
  safeURL: any;
  count: any = 0;
  movieList: any;
  setting: any = '';
  token;
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header: any = document.querySelector('.header');

    const pos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 30) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }
  constructor(
    private service: WalletService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
  }
  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }


  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix')
    console.log(this.id)
    this.getMovieDetail()
    this.getMovieCount()
    this.getSetting()

  }
  getMovieDetail() {
    const body = { movieId: this.id }
    this.service.get_movie_detail(this.token, body).then((res: any) => {
      console.log(res)
      if (res.code == 0) {
        this.detail = res.result
        console.log(res.result.youtube)
        // this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(res.result.youtube);
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/6wSvQXkB5qw&ab_channel=EntertainmentAccess');
      }
    })
  }
  getMovieCount() {
    const body = { movieId: this.id }
    this.service.get_movie_list(this.token, body).then((res: any) => {
      console.log(res)
      let max = 0
      if (res.code == 0) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        max = parseInt(res.result.total)
        this.getMoviesList(Math.floor(Math.random() * (max / 4)))
      }
    })
  }
  getMoviesList(max) {
    const body = { page: max, limit: 4 }
    this.service.get_movie_list(this.token, body).then((res: any) => {
      console.log(res)
      if (res.code == 0) {
        this.movieList = res.result.list
        console.log(this.movieList)
      }
    })
  }
  playMovie() {
    this.router.navigate(['/play-movie/' + this.id], { queryParams: { prefix: this.prefix } });
  }
  onSelect(id) {

    console.log(id)
    // this.movieId = id
    this.detail = ''
    this.reload(id)
    // this.router.navigate(['/movie-detail/'+ id], { queryParams: { prefix: this.prefix } });
    // this.ngOnInit()

  }
  async reload(id): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigate(['/movie-detail/' + id], { queryParams: { prefix: this.prefix } });
  }
  getSetting() {
    this.service.getPrefix({ prefix: atob(this.prefix), ip: '' }).then((res: any) => {
      if (res.code == 0) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        this.setting = res.result
        console.log(this.setting)
      }
    })
  }
  back() {
    this.router.navigate(['/movie'], { queryParams: { prefix: this.prefix } });
  }

}
