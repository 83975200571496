<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container" *ngIf="step ==1">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">ฝากเงิน Askmepay</li>
      </ol>
    </nav>

    <div class="container">
      <div class="mt-3">
        <h3 class="text-center">กรอกจำนวนเงิน</h3>
        <form [formGroup]="amountForm" class="form-signin mt-3 ">
          <div class="form-group">
            <input type="text" name="inputamount" formControlName="amount"
              class="form-control form-control-lg text-center" [(ngModel)]="amount" placeholder="จำนวนเงิน" required
              autofocus>
          </div>
          <div class="text-right" style="font-size: 14px;">สูงสุดไม่เกิน {{minMax.max | number:'1.0-2'}} บาท</div>

          <hr>
          <div class="text-left text-danger" style="font-size: 14px;">
            * กรุณาตรวจสอบรายการของคุณอีกครั้ง แล้วกด ยืนยันการทำรายการ เพื่อทำรายการ
          </div>

          <div style="text-align: center;
              margin-top: 12%;">
            <button class="btn btn-lg btn-rounded shadow btn_login" (click)="clickCreateAskmepay()"
              [disabled]="isDisableAmount">ทำรายการต่อ</button>
          </div>
        </form>
      </div>
    </div>

  </div>

  <div class="container" *ngIf="step ==2">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">{{vpay.id}}</li>
      </ol>
    </nav>

    <div class="container">
      <div class="mt-3 text-center">
        <h5>รายละเอียดการฝากเงิน</h5>

        <div class="block_amount">
          <div class="row mb-2">
            <div class="col-5 text-left">
              เลขที่รายการ:
            </div>
            <div class="col text-right">
              {{vpay.order}}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-5 text-left">
              ประเภท:
            </div>
            <div class="col text-right">
              {{vpay.description}}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-5 text-left">
              ยอดถอน:
            </div>
            <div class="col text-right">
              {{vpay.amount | number:'1.2-2'}} บาท
            </div>
          </div>
          <hr>
          <div class="row mb-2">
            <div class="col-7 text-left">
              สแกน QR Code
              เพื่อทำรายการฝากเงิน
            </div>
            <div class="col text-right">
              <img style="width: 50px; height: auto;" src="{{vpay.value}}" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col" style="font-size: 14px;">
          <div class="mt-2 mb-2" style="font-weight: bold;">ขั้นตอนการฝากเงิน</div>
          <div class="mt-1 mb-1">1. แคปหน้าจอ QR Code</div>
          <div class="mt-1 mb-1">2. เข้าแอปพลิเคชั่นของธนาคารที่ท่านเลือก</div>
          <div class="mt-1 mb-1">3. เลือกเมนู “สแกนจ่าย”</div>
          <div class="mt-1 mb-1">4. กดปุ่มเลือกรูปภาพ</div>
          <div class="mt-1 mb-1">5. เลือกรูป QR Code เพื่อทำการจ่าย</div>
          <div class="mt-1 mb-1"><a id="downloadQRCode" style="color:#22a7f0"
              (click)="downloadQRCode(vpay.value)">ดาวน์โหลด QR Code
              ที่นี้</a></div>
        </div>
      </div>
    </div>

  </div>

</div>

<app-loading-page [isLoading]="isLoading"></app-loading-page>
