import { AlertService } from './../_alert/alert.service';
import { WalletStorageService } from './../../service/wallet-storage.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { WalletService } from 'src/service/wallet.service';

@Component({
  selector: 'app-play-movie',
  templateUrl: './play-movie.component.html',
  styleUrls: ['./play-movie.component.scss']
})
export class PlayMovieComponent implements OnInit {
  isLoading = false;
  prefix: any = '';
  id = '';
  detail: any;
  safeURL: any = null;
  count: any = 0;
  movieList: any;
  setting: any = '';
  token = '';
  logo;
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  collapedSideBar: boolean;


  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header: any = document.querySelector('.header');

    const pos =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 30) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }

  constructor(
    private walletService: WalletService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private alertService: AlertService
  ) {
    this.token = localStorage.getItem('token');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix');

    if (this.token == '') {
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
    const prefixDecode = atob(this.prefix);
    if (prefixDecode != localStorage.getItem('prefix')) {
      localStorage.setItem('token', '')
      this.router.navigate(['/login'], { queryParams: { prefix: this.prefix } });
    }
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');

    if (dom.classList.contains('sidemenu-open')) {
      dom.classList.remove('sidemenu-open');
      setTimeout(() => {
        dom.classList.remove('menuactive');
      }, 800);
    }
  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

  public randomize(): void { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.prefix = this.route.snapshot.queryParamMap.get('prefix')
    console.log(this.id)
    this.getMovieDetail()
    this.getMovieCount()
    this.getSetting()

  }
  getMovieDetail() {
    const body = { movieId: this.id }
    this.walletService.get_movie_detail(this.token, body).then((res: any) => {
      console.log(res)
      if (res.code == 0) {
        this.detail = res.result
        console.log(res.result.file_main_sub)
        if (res.result.sound[0] == 'T') {
          this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(res.result.file_main);
        } else {
          this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(res.result.file_main_sub);
        }
        // this.safeURL = this._sanitizer.bypassSecurityTrustHtml(res.result.file_main_sub);
        // this.safeURL = res.result.file_main_sub
      }
    })
  }
  cleanURL() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.safeURL);
  }
  getMovieCount() {
    const body = { page: 1, limit: 1 }
    this.walletService.get_movie_list(this.token, body).then((res: any) => {
      console.log(res)
      let max = 0
      if (res.code == 0) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        max = parseInt(res.result.total)
        this.getMoviesList(Math.floor(Math.random() * (max / 5)))
      }
    })
  }
  getMoviesList(max) {
    const body = { page: max, limit: 5 }
    this.walletService.get_movie_list(this.token, body).then((res: any) => {
      console.log(res)
      if (res.code == 0) {
        this.movieList = res.result.list
        console.log(this.movieList)
      }
    })
  }
  getSetting() {
    const bodyreq = {
      prefix: atob(this.prefix),
      ip: ''
    }
    this.walletService.getPrefix(bodyreq).then((res: any) => {
      if (res.code == 0) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
        this.setting = res.result
        this.logo = res.result.logo

        console.log(this.setting)
      } else {
        this.alertService.error(res.message, this.options)

      }
    })
  }


  onSelect(id) {

    console.log(id)
    // this.movieId = id
    this.detail = ''
    this.reload(id)
    // this.router.navigate(['/movie-detail/'+ id], { queryParams: { prefix: this.prefix } });
    // this.ngOnInit()

  }
  async reload(id): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigate(['/movie-detail/' + id], { queryParams: { prefix: this.prefix } });
  }

  back() {
    this.router.navigate(['/movie-detail/' + this.id], { queryParams: { prefix: this.prefix } });
  }
}
