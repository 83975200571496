<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<!-- wrapper starts -->
<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">บัญชีผู้ใช้งาน</li>
      </ol>
    </nav>

    <div class="card bg-template shadow mt-4 h-200">

      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-3">
            บัญชีผู้ใช้งาน
          </div>

          <div class="col-auto">
            <figure class="avatar avatar-60"><img style="width: 100%;" src="../../assets/images/bussiness-man.svg"
                alt=""></figure>
          </div>
          <div class="col pl-0 align-self-center">
            <h6 class="mb-1">{{profile.name}}</h6>
            <h6 class="mb-3">{{profile.username}}</h6>

            <p class=" small mb-0">{{profile.bankName}}</p>
            <h6 class="mb-1">{{profile.accountNumber}}</h6>
            <button class="btn" style="border-color: #fff; color: #fff; font-size: 12px;"
              (click)="copyBank()">คัดลอกเลขบัญชี</button>

            <!-- <p class="text-mute small">00512945620</p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mt-3">
        <button class="col btn btn-lg btn-rounded shadow" (click)="clickChangePassword()">
          <span style="float: left; line-height: 2.3;">เปลี่ยนรหัสผ่าน</span><i class="material-icons icons-raised"
            style="color: #000 !important; float: right;">arrow_forward</i>
        </button>
      </div>

      <div class="row mt-3">
        <button class="col btn btn-lg btn-rounded shadow">
          <span style="float: left; line-height: 2.3;">ตั้งค่าการรับโบนัส</span>
          <div>

            <mat-slide-toggle style="margin:10px; float: right;" [(ngModel)]="isBonus" (change)="changeIsBonus()">
            </mat-slide-toggle>

          </div>
        </button>
      </div>
    </div>


    <div style="text-align: center;
    margin-top: 12%;">
      <button class="btn btn-lg btn-rounded shadow btn_login" (click)="back()">กลับสู่หน้าหลัก</button>
    </div>
  </div>
</div>
<!-- page content ends -->
<!-- </div>
    </div> -->
<!-- </div> -->
<!-- wrapper ends -->

<app-loading-page [isLoading]="isLoading"></app-loading-page>
