<app-sidebar (collapsedEvent)="receiveCollapsed($event)" class="sidebar"></app-sidebar>
<a href="javascript:void(0)" class="closesidemenu" (click)="toggleSidebar()"><i
    class="material-icons icons-raised bg-dark ">close</i></a>

<div class="wrapper" [ngClass]="{'blur': isLoading}">
  <app-header class="container-fluid header" style="z-index: 3;" id="header-container"></app-header>


  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" (click)="back()"><a>หน้าแรก</a></li>
        <li class="breadcrumb-item active txtMem">ฝากเงินออโต้</li>
      </ol>
    </nav>
    <div class="mt-3">
      <h5 class="text-center">ข้อมูลการโอนเงิน</h5>
      <div class="card bg-template shadow mt-4 h-200">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              บัญชีรับโอนเงิน
            </div>

            <div class="col-auto">
              <figure class="avatar avatar-60"><img style="width: 100%;" src="../../assets/images/bussiness-man.svg"
                  alt=""></figure>
            </div>
            <div class="col pl-0 align-self-center">
              <p class=" small">{{bankDepositDecimal.bankName}}</p>
              <h6 class="mb-1">ชื่อบัญชี {{bankDepositDecimal.accountName}}</h6>
              <h6 class="mb-1">{{bankDepositDecimal.accountNumber}}</h6>
              <button class="btn" style="border-color: #fff; color: #fff;" (click)="copyBank()">คัดลอกเลขบัญชี</button>

              <!-- <p class="text-mute small">00512945620</p> -->
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4 mb-4">

        <div class="col-auto" style="margin: 5px -20px 10px 10px;">
          <figure class="avatar avatar-60"><img style="width: 100%;" src="../../assets/images/warn.svg" alt="">
          </figure>
        </div>
        <div class="col">
          <div class="text-danger">* ระบบจะเติมเงินอัตโนมัติ เมื่อท่าน </div>
          <div class="text-danger" style="text-decoration: underline;">โอนเงินพร้อมทศนิยมและใช้บัญชีที่สมัครโอนเท่านั้น
          </div>
        </div>
      </div>

      <div class="card-body block_amount">
        <div class="row">
          <div class="col pl-0 align-self-center">
            <h3 class="mb-1">ยอดโอน : {{bankDepositDecimal.amount}}</h3>
            <div>
              <h6 class="mb-1">Transaction :
                <countdown [config]="{ leftTime: 120, format: 'mm:ss' }" (event)="handleEvent($event)">
                </countdown>
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4 mb-4">
        <div class="col">
          <div class="text-danger">** หากไม่ได้รับเครดิต หลังโอนเกิน 2 นาที ส่งสลิปแจ้งแอดมินได้เลยค่ะ
            <button class="btn btn-rounded shadow " style="font-size: 10px;
            background-color: #00bb00;
            color: #fff;">line@ คลิกที่นี่</button>
          </div>
        </div>
      </div>

      <div style="text-align: center;
    margin-top: 12%;">
        <button class="btn btn-lg btn-rounded shadow btn_login" (click)="back()">กลับไปหน้าเติมเงิน</button>
      </div>

    </div>
  </div>
</div>

<app-loading-page [isLoading]="isLoading"></app-loading-page>
