import { PlayMovieComponent } from './play-movie/play-movie.component';
import { MovieDetailComponent } from './movie-detail/movie-detail.component';
import { MoviesComponent } from './movies/movies.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AccountPageComponent } from './account-page/account-page.component';
import { DepositCreditCardPageComponent } from './deposit-credit-card-page/deposit-credit-card-page.component';
import { WithdrawPointPageComponent } from './withdraw-point-page/withdraw-point-page.component';
import { LinkGetPointPageComponent } from './link-get-point-page/link-get-point-page.component';
import { PromotionPageComponent } from './promotion-page/promotion-page.component';
import { ReportPageComponent } from './report-page/report-page.component';
import { DepositCouponPageComponent } from './deposit-coupon-page/deposit-coupon-page.component';
import { DepositTruemoneyPageComponent } from './deposit-truemoney-page/deposit-truemoney-page.component';
import { WithdrawPageComponent } from './withdraw-page/withdraw-page.component';
import { DepositDecimalPageComponent } from './deposit-decimal-page/deposit-decimal-page.component';
import { DepositAutoPageComponent } from './deposit-auto-page/deposit-auto-page.component';
import { ForgetPasswordPageComponent } from './forget-password-page/forget-password-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SignupPageComponent } from './signup-page/signup-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './login-page/login-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { DepositDecimalInfoPageComponent } from './deposit-decimal-info-page/deposit-decimal-info-page.component';
import { DepositAskmepayPageComponent } from './deposit-askmepay-page/deposit-askmepay-page.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: 'login',
    component: LoginPageComponent
  }, {
    path: 'dashboard',
    component: DashboardPageComponent
  }, {
    path: 'sign-up',
    component: SignupPageComponent
  }, {
    path: 'forget-password',
    component: ForgetPasswordPageComponent
  }, {
    path: 'deposit-auto',
    component: DepositAutoPageComponent
  }, {
    path: 'deposit-decimal',
    component: DepositDecimalPageComponent
  }, {
    path: 'deposit-decimal-info',
    component: DepositDecimalInfoPageComponent
  }, {
    path: 'deposit-truemoney',
    component: DepositTruemoneyPageComponent
  }, {
    path: 'deposit-askmepay',
    component: DepositAskmepayPageComponent
  }, {
    path: 'deposit-credit-card',
    component: DepositCreditCardPageComponent
  }, {
    path: 'deposit-coupon',
    component: DepositCouponPageComponent
  }, {
    path: 'report',
    component: ReportPageComponent
  }, {
    path: 'withdraw',
    component: WithdrawPageComponent
  }, {
    path: 'withdraw-point',
    component: WithdrawPointPageComponent
  }, {
    path: 'promotion',
    component: PromotionPageComponent
  }, {
    path: 'link-get-point',
    component: LinkGetPointPageComponent
  }, {
    path: 'account',
    component: AccountPageComponent
  }, {
    path: 'change-password',
    component: ChangePasswordComponent
  }, {
    path: 'movie',
    component: MoviesComponent
  }, {
    path: 'movie-detail/:id',
    component: MovieDetailComponent
  }, {
    path: 'play-movie/:id',
    component: PlayMovieComponent
  }, {
    path: "**",
    component: ErrorPageComponent
  }

];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }