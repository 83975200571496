<div class="login-page">
  <div class="wrapper vh-100" style="overflow: auto;">
    <div class="mt-3 ml-3" (click)="back()">
      < ล็อกอิน</div> <div class="row no-gutters h-100 login-row">

        <div class="col align-self-center px-3 text-center">

          <br>
          <img src="{{logo}}" alt="logo" class="logo-small">
          <h3 class="mt-3">ลืมรหัสผ่าน</h3>
          <form [formGroup]="mobileForm" class="form-signin mt-3 ">
            <div class="form-group">
              <input type="tel" formControlName="mobile" name="inputPhone"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                [(ngModel)]="phone" class="form-control form-control-lg text-center"
                placeholder="หมายเลขโทรศัพท์ที่ลงทะเบียน" maxlength="10" [ngClass]="phone.length != 10 ? 'req': ''"
                required autofocus>
              <div class="text-left text-danger" style="font-size: 12px;" *ngIf=" phone.length != 10">
                *กรุณาระบุเบอร์โทรศัพท์
              </div>
            </div>

            <div style="margin-top: 30%;">
              <button class="btn btn-lg btn-rounded shadow btn-block btn_login" [disabled]="phone.length != 10"
                (click)="forgetPassword()">ขอรับรหัสผ่านใหม่</button>
            </div>

          </form>
        </div>
    </div>
  </div>
</div>
