import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WalletService {
  // test
  // private baseUrl = 'http://178.128.210.196:22111/iamrobot';

  // prod
  private baseUrl = 'https://ambbo-test-support.prettygaming.asia/iamrobot'
  constructor(
    private http: HttpClient
  ) { }

  public login(bodyReq): any {
    // const httpOptions = {
    //   withCredentials: true

    // };
    const url = this.baseUrl + `/frontend/user/login`;
    return this.http.post(url, bodyReq).toPromise();
  }

  public getPrefix(bodyReq): any {
    // const httpOptions = {
    //   withCredentials: true

    // };
    const url = this.baseUrl + `/frontend/prefix/v2`;
    return this.http.post(url, bodyReq).toPromise();
  }

  public getProfile(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/user/profile`;
    return this.http.get(url, httpOptions).toPromise();
  }

  public getBank(): any {
    const url = this.baseUrl + `/master/bank`;
    return this.http.get(url).toPromise();
  }

  public getCaptcha(prefix): any {
    const url = this.baseUrl + `/frontend/user/captcha/${prefix}`;
    return this.http.get(url).toPromise();
  }

  public checkRegister(bodyReq): any {
    // const httpOptions = {
    //   withCredentials: true

    // };
    const url = this.baseUrl + `/frontend/user/check-register`;
    return this.http.post(url, bodyReq).toPromise();
  }

  public Register(bodyReq): any {
    // const httpOptions = {
    //   withCredentials: true

    // };
    const url = this.baseUrl + `/frontend/user/register`;
    return this.http.post(url, bodyReq).toPromise();
  }

  public forgetPassword(bodyReq): any {
    // const httpOptions = {
    //   withCredentials: true

    // };
    const url = this.baseUrl + `/frontend/user/forgot-password`;
    return this.http.put(url, bodyReq).toPromise();
  }

  public getBankDeposit(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/bank/non-promptPay`;
    return this.http.get(url, httpOptions).toPromise();
  }

  public createDepositDecimal(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/depositPoint/create`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public getTrueMoney(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/trueMoney`;
    return this.http.get(url, httpOptions).toPromise();
  }

  public getMinMax(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/vpay/get-min-max`;
    return this.http.get(url, httpOptions).toPromise();
  }

  public getMinMaxCredit(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/creditCard/get-min-max`;
    return this.http.get(url, httpOptions).toPromise();
  }

  public getInitVpay(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/vpay/init-payment`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public getInitCreditCard(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/creditCard/init-payment`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public useCoupon(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/coupon/use`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public condition(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/withdraw/condition`;
    return this.http.get(url, httpOptions).toPromise();
  }

  public withdrawCredit(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const body = {}
    const url = this.baseUrl + `/frontend/withdraw/credit`;
    return this.http.post(url, body, httpOptions).toPromise();
  }


  public getReportDeposit(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/report/deposit`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public getReportWithdraw(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/report/withdraw`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public getPromotion(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/promotion/v2`;
    return this.http.get(url, httpOptions).toPromise();
  }

  public getAffiliate(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/affiliate/v2`;
    return this.http.get(url, httpOptions).toPromise();
  }

  public conditionPoint(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const body = {}
    const url = this.baseUrl + `/frontend/withdraw/condition/point`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public withdrawPoint(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    // const body = {}
    const url = this.baseUrl + `/frontend/withdraw/point`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public newReportAffiliate(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const body = {}
    const url = this.baseUrl + `/frontend/affiliate/new`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public getReward(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    // const body = {}
    const url = this.baseUrl + `/frontend/user/link-reward`;
    return this.http.get(url, httpOptions).toPromise();
  }

  public checkCashback(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const body = {}
    const url = this.baseUrl + `/frontend/user/check-cash-back/v2`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public getCashback(token): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const body = {}
    const url = this.baseUrl + `/frontend/user/cash-back/v2`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public setBonus(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/user/setting`;
    return this.http.put(url, body, httpOptions).toPromise();
  }

  public changePasswordNew(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/user/change-password`;
    return this.http.put(url, body, httpOptions).toPromise();
  }

  public get_game_path(token, link): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    // const body = {}
    const url = this.baseUrl + `/frontend/user/${link}`;
    return this.http.get(url, httpOptions).toPromise();
  }


  public get_movie_list(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/entertain/list`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

  public get_movie_detail(token, body): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
    const url = this.baseUrl + `/frontend/entertain/detail`;
    return this.http.post(url, body, httpOptions).toPromise();
  }

}
